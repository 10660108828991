import { cloneDeep } from 'lodash';
import { AmazonRelayInfo, Company, CreateCompanyRequest, CreateIntegrationRequest } from '../../../api';

export type Preparing = { type: 'preparing' };

export type Connected = { type: 'connected' };

export type AuthenticationFailed = {
  type: 'authentication_failed';
  integration_id: string;
};

export type CaptchaRequired = {
  type: 'captcha_required';
  captcha: string;
  instance_id: string;
};

export type VerificationRequired = {
  type: 'verification_required';
  instance_id: string;
  message: string;
};

export type VerificationCodeRequired = {
  type: 'verification_code_required';
  instance_id: string;
  message: string;
};

export type ExpiredTrialPeriod = {
  type: 'expired_trial_period';
  integration_id: string;
};

export type ConnectionEvent =
  | Preparing
  | AuthenticationFailed
  | CaptchaRequired
  | VerificationRequired
  | VerificationCodeRequired
  | ExpiredTrialPeriod
  | Connected;

export interface WizardState {
  open: boolean;
  stepIndex: number;
  initialLegendId?: string;
  initialCompany?: Company;
  companyName?: string;
  selectedCompany?: Company;
  createCompanyReq?: CreateCompanyRequest;
  createIntegrationReq: CreateIntegrationRequest;
  showConnectStepOnly?: boolean;
  event?: ConnectionEvent;
}

export type WizardPopupAction =
  | { type: 'OPEN'; payload: { company?: Company; legendId?: string } }
  | { type: 'CLOSE'; payload: {} }
  | { type: 'BACK'; payload: {} }
  | { type: 'NEXT'; payload: {} }
  | { type: 'SELECTED_COMPANY'; payload: Company }
  | { type: 'COMPANY_CREATED'; payload: Company }
  | { type: 'PENDING_CREATE_COMPANY' }
  | { type: 'CANCEL_CREATE_COMPANY'; payload: CreateCompanyRequest }
  | { type: 'AMAZON_RELAY_READY'; payload: CreateIntegrationRequest }
  | { type: 'NEW_COMPANY_FILLED'; payload: CreateCompanyRequest }
  | { type: 'RECEIVE_EVENT'; payload: ConnectionEvent }
  | { type: 'RESET_EVENT'; payload: {} };

export const wizardReducer = (state: WizardState, action: WizardPopupAction): WizardState => {
  switch (action.type) {
    case 'OPEN':
      return {
        ...state,
        open: true,
        stepIndex: 0,
        initialLegendId: action.payload.legendId,
        initialCompany: action.payload.company,
        createIntegrationReq: getInitialValueFromCompany(action.payload.company),
      };
    case 'CLOSE':
      return {
        open: false,
        stepIndex: 0,
        selectedCompany: undefined,
        companyName: undefined,
        createCompanyReq: undefined,
        createIntegrationReq: defaultCreateIntegrationReq,
        showConnectStepOnly: false,
      };
    case 'BACK':
      return state.open
        ? {
          ...state,
          open: state.stepIndex > 0,
          stepIndex: state.stepIndex === 0 ? 0 : state.stepIndex - 1,
        }
        : state;
    case 'NEXT':
      return {
        ...state,
        stepIndex: state.stepIndex + 1,
      };
    case 'SELECTED_COMPANY':
      return {
        ...state,
        selectedCompany: action.payload,
        companyName: action.payload.name,
        createCompanyReq: undefined,
        createIntegrationReq: {
          ...state.createIntegrationReq,
          entity: { id: action.payload.id, name: 'Company' as const },
        },
      };
    case 'PENDING_CREATE_COMPANY':
      return {
        ...state,
        selectedCompany: undefined,
        createIntegrationReq: {
          ...state.createIntegrationReq,
          entity: { id: '', name: 'Company' },
        },
      };
    case 'COMPANY_CREATED':
      return {
        ...state,
        selectedCompany: action.payload,
        createCompanyReq: undefined,
        createIntegrationReq: {
          ...state.createIntegrationReq,
          entity: { id: action.payload.id, name: 'Company' },
        },
      };
    case 'AMAZON_RELAY_READY':
      return {
        ...state,
        createIntegrationReq: {
          ...state.createIntegrationReq,
          ...action.payload,
        },
      };
    case 'NEW_COMPANY_FILLED':
      return {
        ...state,
        companyName: action.payload.name,
        createCompanyReq: action.payload,
      };
    case 'CANCEL_CREATE_COMPANY':
      return {
        ...state,
        createCompanyReq: action.payload,
      };
    case 'RECEIVE_EVENT':
      return {
        ...state,
        open: true,
        // when receive events from socket and wizard is not open, open popup
        // and show connect step only
        showConnectStepOnly: !state.open ? true : state.showConnectStepOnly,
        event: action.payload,
      };
    case 'RESET_EVENT':
      return {
        ...state,
        showConnectStepOnly: false,
        event: undefined,
      };
  }
};

export const defaultCreateIntegrationReq: CreateIntegrationRequest = {
  type: 'legend',
  settings: {
    domain: '',
    credentials: {
      login: '',
      password: '',
    },
    carrier_type: 'local',
    carrier_description: '',
  },
  entity: { id: '', name: 'Company' as const },
};

export function getInitialValueFromAmazonRelayInfo(amazonRelayInfo?: AmazonRelayInfo) {
  const req = cloneDeep(defaultCreateIntegrationReq);
  if (!amazonRelayInfo) {
    return req;
  }
  req.settings.domain = amazonRelayInfo.domain;
  req.settings.carrier_type = amazonRelayInfo.carrier_type;
  req.settings.carrier_description = amazonRelayInfo.carrier_description;
  return req;
}

export function getInitialValueFromCompany(company?: Company) {
  const req = cloneDeep(defaultCreateIntegrationReq);
  if (!company) {
    return req;
  }
  req.settings.domain = company.integrations[0].settings.domain.id;
  return req;
}
