import { ArrowForward } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Box, Button, FormControl, LinearProgress, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import { apiClient } from '../../../api';
import { useWizardPopup } from '../../../components/wizard_popup';
import { AmazonRelayForm, useAmazonRelayForm } from './AmazonRelayForm';
import { AuthenticationFailed, CaptchaRequired, getInitialValueFromAmazonRelayInfo, VerificationCodeRequired, VerificationRequired, WizardState } from './reducer';
import { StepHeader } from './StepHeader';

export interface ConnectStepProps {
  wizardState: WizardState;
  onFinish: () => void;
}

export const ConnectStep: FC<ConnectStepProps> = ({ onFinish, wizardState }) => {
  const queryClient = useQueryClient();
  const { resetEvent } = useWizardPopup();

  const handleFinish = useCallback(() => {
    queryClient.invalidateQueries('companies');
    queryClient.invalidateQueries('requests');
    onFinish();
  }, []);

  switch (wizardState.event?.type) {
    case 'connected':
      return <ConnectedScreen onFinish={handleFinish} />;
    case 'authentication_failed':
      return (
        <AuthenticationFailedScreen
          wizardState={wizardState}
          onNext={resetEvent}
        />
      );
    case 'captcha_required':
      return (
        <CaptchaScreen
          wizardState={wizardState}
          onNext={resetEvent}
        />
      );
    case 'verification_required':
      return (
        <VerifiCationScreen
          wizardState={wizardState}
        />
      );
    case 'verification_code_required':
      return (
        <VerificationCodeScreen
          wizardState={wizardState}
          onNext={resetEvent}
        />
      );
    case 'expired_trial_period':
      return (
        <AuthenticationFailedScreen
          wizardState={wizardState}
          alert={{
            title: 'You are logged in to company with an expired free Legend trial.',
            description: 'Please subscribe or enter new credentials',
          }}
          onNext={resetEvent}
        />
      );
    default:
      return <WaitingScreen />;
  }
};

const WaitingScreen = () => {
  return (
    <Stack
      flexGrow={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <StepHeader
        title="Connecting"
        description="Connection may take up to 10 minutes. Please wait..."
      />
      <Box className="step-body" flexGrow={1}>
        <Box mb={2}>
          <LinearProgress value={10} />
        </Box>
        <Alert severity="warning" variant="filled" color="warning">
          <AlertTitle>We need your attention!</AlertTitle>
          Do not close the browser. Do not navigate away from this page.
        </Alert>
      </Box>
    </Stack>
  );
};

interface SuccessScreenProps {
  onFinish: () => void;
}

const ConnectedScreen: FC<SuccessScreenProps> = ({ onFinish }) => {
  return (
    <Stack
      flexGrow={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <StepHeader title="You are Done!" />
      <Box className="step-body" flexGrow={1}>
        <Alert severity="success" variant="filled">
          <AlertTitle>Success</AlertTitle>
          Legend has been successfully connected to your Amazon Relay account.
        </Alert>
      </Box>
      <Stack className="step-footer" flexDirection="row" justifyContent="flex-end">
        <Button variant="contained" endIcon={<ArrowForward />} onClick={onFinish}>
          Finish
        </Button>
      </Stack>
    </Stack>
  );
};

interface AuthenticationFailedScreenProps {
  wizardState: WizardState;
  onNext: () => void;
  alert?: { title: string; description: string };
}

const AuthenticationFailedScreen: FC<AuthenticationFailedScreenProps> = ({
  wizardState,
  onNext,
  alert: {
    title = 'There was a problem!',
    description = 'Your password is incorrect.',
  } = {},
}) => {
  const { integration_id } = wizardState.event as AuthenticationFailed;
  const amazonRelayForm = useAmazonRelayForm({
    initialValues: getInitialValueFromAmazonRelayInfo(wizardState.createIntegrationReq!.settings),
    onSubmit: async (values) => {
      await apiClient.updateIntegration({
        id: integration_id,
        settings: values.settings,
      });
      onNext();
    },
  });
  return (
    <Stack
      flexGrow={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <StepHeader title="Warning!" />
      <Box className="step-body" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Alert severity="warning" variant="filled">
          <AlertTitle>{title}</AlertTitle>
          {description}
        </Alert>
        <AmazonRelayForm {...amazonRelayForm} />
      </Box>
      <Stack className="step-footer" flexDirection="row" justifyContent="flex-end">
        <LoadingButton
          variant="contained"
          endIcon={<ArrowForward />}
          onClick={amazonRelayForm.submitForm}
          loading={amazonRelayForm.isSubmitting}
          disabled={!amazonRelayForm.isValid}
        >
          Connect
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

interface CaptchaScreenProps {
  wizardState: WizardState;
  onNext: () => void;
}

const captchaSchema = yup.object({
  value: yup.string().required(),
});

const CaptchaScreen: FC<CaptchaScreenProps> = ({
  wizardState,
  onNext,
}) => {
  const { instance_id, captcha } = wizardState.event as CaptchaRequired;
  const {
    handleSubmit,
    handleChange,
    submitForm,
    values,
    errors,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues: {
      value: '',
    },
    validationSchema: captchaSchema,
    isInitialValid: false,
    onSubmit: async () => {
      await apiClient.validateCaptcha({
        id: instance_id,
        captcha: values.value,
      });
      onNext();
    },
  });
  return (
    <Stack
      flexGrow={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <StepHeader title="Warning!" />
      <Alert severity="warning" variant="filled">
        <AlertTitle>Enter Captcha!</AlertTitle>
        Enter captcha below.
      </Alert>
      <img src={captcha} width="100%" className="mt-4" />
      <Box>
        <form noValidate onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <TextField
              name="value"
              value={values.value}
              onChange={handleChange}
              label="Captcha"
              size="small"
              required
              error={!!errors.value}
              helperText={errors.value}
            />
          </FormControl>
        </form>
      </Box>
      <Stack className="step-footer" flexDirection="row" justifyContent="flex-end">
        <LoadingButton
          variant="contained"
          endIcon={<ArrowForward />}
          onClick={submitForm}
          loading={isSubmitting}
          disabled={!isValid}
        >
          Connect
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

interface VerifiCationScreenProps {
  wizardState: WizardState;
}

const VerifiCationScreen: FC<VerifiCationScreenProps> = ({ wizardState }) => {
  const { message } = wizardState.event as VerificationCodeRequired;
  return (
    <Stack
      flexGrow={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <StepHeader
        title="Connecting"
        description="Connection may take up to 10 minutes. Please wait..."
      />
      <Box className="step-body" flexGrow={1}>
        <Alert severity="warning" variant="filled">
          <AlertTitle>To continue, approve the notification sent to</AlertTitle>
          {message}
        </Alert>
      </Box>
    </Stack>
  );
};

interface VerificationCodeScreenProps {
  wizardState: WizardState;
  onNext: () => void;
}

const verificationCodeSchema = yup.object({
  value: yup.string().required('OTP is required'),
});

const VerificationCodeScreen: FC<VerificationCodeScreenProps> = ({
  wizardState,
  onNext,
}) => {
  const { instance_id, message } = wizardState.event as VerificationCodeRequired;
  const {
    handleSubmit,
    handleChange,
    submitForm,
    values,
    errors,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues: {
      value: '',
    },
    isInitialValid: false,
    validationSchema: verificationCodeSchema,
    onSubmit: async (values) => {
      await apiClient.validateOTP({ id: instance_id, verification_code: values.value });
      onNext();
    },
  });
  return (
    <Stack
      flexGrow={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <StepHeader title="Warning!" />
      <Alert severity="warning" variant="filled">
        <AlertTitle>Two-step Verification</AlertTitle>
        {message}
      </Alert>
      <Box>
        <form noValidate onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <TextField
              name="value"
              value={values.value}
              onChange={handleChange}
              label="Enter OTP"
              size="small"
              required
              error={!!errors.value}
              helperText={errors.value}
            />
          </FormControl>
        </form>
      </Box>
      <Stack className="step-footer" flexDirection="row" justifyContent="flex-end">
        <LoadingButton
          variant="contained"
          endIcon={<ArrowForward />}
          onClick={submitForm}
          loading={isSubmitting}
          disabled={!isValid}
        >
          Connect
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
