import { Clear } from '@mui/icons-material';
import { IconButton } from '@mui/material';

interface ClearableSelectProps {
  value?: unknown;
  onClear: () => void;
}

export const clearableProps = ({ value, onClear }: ClearableSelectProps) => {
  return {
    sx: {
      '& .MuiSelect-iconOutlined': {
        display: value ? 'none' : undefined,
      },
    },
    endAdornment: value !== undefined && (
      <IconButton onClick={onClear}>
        <Clear />
      </IconButton>
    ),
  };
};
