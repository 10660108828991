import { FC } from 'react';
import { IconProps } from './common';

export const CalendarWeekly: FC<IconProps> = ({ size = 16 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 49 48"
    >
      <path
        fill="currentcolor"
        fillRule="evenodd"
        d="M16.102 27.917c-.91 0-1.648-.761-1.648-1.7 0-.938.738-1.717 1.648-1.717h.017c.893 0 1.63.761 1.63 1.7.018.938-.72 1.717-1.647 1.717zm8.24 0c-.91 0-1.665-.761-1.665-1.7 0-.938.738-1.7 1.648-1.717h.017c.91.018 1.648.779 1.648 1.7 0 .956-.738 1.717-1.648 1.717zm6.575-1.7c0 .939.738 1.7 1.648 1.7.91 0 1.648-.761 1.648-1.7 0-.938-.738-1.7-1.648-1.7-.927 0-1.648.762-1.648 1.7zm-.017 7.259c.017.938.756 1.7 1.665 1.7.91 0 1.648-.762 1.648-1.7s-.738-1.7-1.648-1.7h-.017c-.91 0-1.648.762-1.648 1.7zm-8.223 0c0 .938.755 1.7 1.665 1.7s1.648-.762 1.648-1.718c-.017-.92-.738-1.682-1.648-1.7h-.017c-.91.018-1.648.78-1.648 1.718zm-8.223 0c0 .938.738 1.7 1.648 1.7.91 0 1.648-.78 1.648-1.718s-.738-1.7-1.648-1.7c-.927 0-1.665.78-1.648 1.718z"
        clipRule="evenodd"
      >
      </path>
      <path
        fill="currentcolor"
        fillRule="evenodd"
        d="M8.833 18v15.82c0 2.921.804 4.69 2 5.773 1.242 1.124 3.345 1.907 6.745 1.907h13.494c3.41 0 5.516-.783 6.76-1.908 1.198-1.082 2.001-2.85 2.001-5.771V18a1.5 1.5 0 00-1.5-1.5h-28a1.5 1.5 0 00-1.5 1.5zm-2.5 0a4 4 0 014-4h28a4 4 0 014 4v15.82c0 6.763-3.776 10.18-11.261 10.18H17.578C10.11 44 6.333 40.583 6.333 33.82V18z"
        clipRule="evenodd"
      >
      </path>
      <path
        fill="currentcolor"
        d="M15.433 7.434V7.42l17.821.007c.96.089 1.908.278 2.833.564v-.014c.052.024.091.038.16.062l.011.004.09.033c.04.016.08.03.125.045.19.062.367.136.55.211l.031.014c.084.03.164.066.235.099l.067.03c.151.066.306.145.43.21.081.043.162.089.237.132l.117.066c.062.034.115.07.166.105a8.665 8.665 0 011.551 1.239l.016.027c.161.162.314.332.456.512l.012.025a10.605 10.605 0 011.978 6.746v.775H6.347v-.768a10.596 10.596 0 011.985-6.742l.026-.025c.087-.107.179-.212.273-.319l.17-.194.025-.026a8.194 8.194 0 011.427-1.166l.11-.07c.06-.038.119-.076.182-.108l.343-.198c.07-.033.142-.069.214-.105l.215-.105c.05-.02.101-.042.152-.065a7.702 7.702 0 01.747-.29c.069-.033.14-.06.214-.077a.737.737 0 01.094-.037 1.22 1.22 0 00.078-.03v.011a13.747 13.747 0 012.83-.564z"
      >
      </path>
      <path
        fill="currentcolor"
        d="M33.334 5.434v6.303c0 .794-.645 1.437-1.44 1.437-.796 0-1.44-.643-1.44-1.437V5.448A1.437 1.437 0 0131.883 4a1.438 1.438 0 011.45 1.427v.007zM18.213 5.457v6.287c0 .798-.645 1.444-1.44 1.444-.796 0-1.44-.646-1.44-1.444V5.457a1.44 1.44 0 112.88 0z"
      >
      </path>
    </svg>
  );
};
