import { useCallback, useRef } from 'react';
import beepWav from '../../assets/beep.wav';

export const useBeepAudio = () => {
  const audioRef = useRef<HTMLAudioElement>(null);

  const stop = () => {
    audioRef.current?.pause();
    audioRef.current?.load();
  };

  const onClickSomewhere = useCallback(() => {
    stop();
    window.removeEventListener('click', onClickSomewhere);
  }, []);

  const play = () => {
    audioRef.current?.play();
    window.addEventListener('click', onClickSomewhere);
  };

  return {
    audioRef,
    play,
    stop,
    audioElement: <audio ref={audioRef} src={beepWav} loop></audio>,
  };
};
