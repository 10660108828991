import { Close, QuestionMark } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Backdrop, Box, DialogContent, DialogTitle, Fade, Grid, IconButton, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { FC, memo, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { apiClient, CheckRequestsResponse, Load } from '../../api';
import { BootstrapDialog } from '../../components/dialog';
import { CompanySelector } from './CompanySelector';
import { RequestSelector } from './RequestSelector';

export interface RequestCheckPopupProps {
  load: Load;
}

export const RequestCheckPopup: FC<RequestCheckPopupProps> = ({ load }) => {
  return (
    <PopupState variant="popover">
      {popupState => {
        return (
          <div>
            <IconButton {...bindTrigger(popupState)}>
              <QuestionMark />
            </IconButton>
            <RequestCheckModal loadId={load.id} {...bindPopover(popupState)} />
          </div>
        );
      }}
    </PopupState>
  );
};

interface RequestCheckModalProps {
  loadId: string;
  open: boolean;
  onClose: () => void;
}

const RequestCheckModal: FC<RequestCheckModalProps> = memo(({
  loadId,
  open,
  onClose,
}) => {
  const [companyId, setCompanyId] = useState<string>();
  const [selectedRequests, setSelectedRequests] = useState<string[]>([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const check = useMutation<CheckRequestsResponse, Error>({
    mutationKey: ['requestCheck', selectedRequests],
    mutationFn: () =>
      apiClient.checkRequests({
        load: { id: loadId },
        requests: Array.from(selectedRequests).map(id => ({ id })),
      }),
  });

  useEffect(() => {
    return () => {
      setCompanyId(undefined);
      setSelectedRequests([]);
      check.reset();
    };
  }, []);

  if (!open) return null;

  return (
    <BootstrapDialog
      open={open}
      fullScreen={fullScreen}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: Fade,
        },
      }}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return;
        }
        onClose();
      }}
      closeAfterTransition
      sx={{
        '& .MuiDialog-paper': {
          width: '960px',
          maxWidth: '100%',
        },
        '.MuiDialogTitle-root': {
          padding: '20px 30px',
        },
        '& .MuiDialogContent-root': {
          padding: '0 30px 30px 30px',
        },
        '& .MuiDialogActions-root': {
          padding: '20px 30px',
        },
      }}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontWeight: 800, fontSize: '18px', lineHeight: '23px' }}>Request Check</Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} md={3}>
            <CompanySelector
              value={companyId}
              onChange={company => {
                setCompanyId(company);
                setSelectedRequests([]);
                check.reset();
              }}
            />
          </Grid>
          <Grid item xs={12} md={7} overflow="hidden">
            <RequestSelector
              label="Company"
              company_id={companyId}
              value={Array.from(selectedRequests)}
              onChange={(ids) => {
                setSelectedRequests(ids);
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <LoadingButton
              variant="contained"
              loading={check.isLoading}
              fullWidth
              onClick={() => check.mutateAsync()}
            >
              Check
            </LoadingButton>
          </Grid>
          <Grid item xs={12} minHeight={200}>
            {check.error && <Alert severity="error">{String(check.error)}</Alert>}
            {check.data
              ? (
                <Box flexDirection="column" rowGap={1} sx={{ display: 'flex' }}>
                  {check.data.messages.map(msg => {
                    return (
                      <Paper key={msg.request.id} variant="outlined" sx={{ padding: 1 }}>
                        <Typography>
                          Request #{msg.request.number} - {msg.message}
                        </Typography>
                      </Paper>
                    );
                  })}
                </Box>
              )
              : (
                <Paper
                  variant="outlined"
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
                >
                  Select Requests to see why this load was not booked.
                </Paper>
              )}
          </Grid>
        </Grid>
      </DialogContent>
    </BootstrapDialog>
  );
});
