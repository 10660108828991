import axios from 'axios';
import { AuthModel, UserModel } from './_models';

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/me`;
export const LOGIN_URL = `${API_URL}/login`;
export const REGISTER_URL = `${API_URL}/v1/users`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;
export const VALIDATE_USER = `${API_URL}/v1/users/validate`;
export const VERIFY_EMAIL = `${API_URL}/v1/users/send-email-verification-code`;
export const CHECK_EMAIL_CODE = `${API_URL}/v1/users/check-email-verification-code`;
export const USER_SIGN_IN = `${API_URL}/auth/session`;
export const FORGOT_PASSWORD_CODE = `${API_URL}/v1/users/send-forgot-password-code`;
export const CHANGE_FORGOT_PASSWORD = `${API_URL}/v1/users/change-forgot-password`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  });
}

// Server should return AuthModel
export async function validateUser(email: string, password: string) {
  const data = await axios.post(VALIDATE_USER, { email, password });
  await sendCodeToEmail(email);
  return data;
}

export async function sendCodeToEmail(email: string) {
  return await axios.post(VERIFY_EMAIL, { email });
}

export async function generatePassword() {
  return await axios.get(`${REGISTER_URL}/password`);
}

export async function sendForgotPasswordCode(email: string) {
  await axios.post(FORGOT_PASSWORD_CODE, { email });
}

export async function checkEmailCode(email: string, code: string) {
  return await axios.post(CHECK_EMAIL_CODE, { email, code });
}

export async function register(
  email: string,
  name: string,
  password: string,
  code: string,
) {
  return await axios.post(REGISTER_URL, {
    email,
    name,
    password,
    code,
  });
}

export async function signIn(email: string, password: string) {
  return await axios.post(USER_SIGN_IN, { email, password });
}

// Server should return object => { result: boolean } (Is Email in DB)
export async function requestPassword(
  email: string,
  code: string,
  password: string,
) {
  return await axios.put(CHANGE_FORGOT_PASSWORD, { email, password, code });
}

export async function getUserByToken(token: string) {
  const { data } = await axios.get(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const {
    id,
    name: first_name,
    email,
    phone_number: phone,
    password = undefined,
    isAdmin = data.data.acl.roles,
  } = data.data;
  const user: UserModel = { id, first_name, email, phone, password, isAdmin };
  return user;
}
