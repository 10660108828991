import { QueryClient, useQuery, UseQueryOptions } from 'react-query';
import { apiClient, Constants } from '../api';

export const useConstants = (options: UseQueryOptions<Constants> = {}) =>
  useQuery({
    queryKey: ['constants'],
    queryFn: () => apiClient.getConstants(),
    ...options,
  });

export const prefetchConstants = (queryClient: QueryClient) => {
  queryClient.prefetchQuery({
    queryKey: ['constants'],
    queryFn: () => apiClient.getConstants(),
    staleTime: 1000 * 60 * 5,
  });
};
