import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface UrlParams {
  name?: string;
  carrier_id?: string;
  plan_id?: string;
}

function useGetUrlParams() {
  const [searchParams] = useSearchParams();
  const [companyNameParam, setCompanyNameParam] = useState<string | null>(null);
  const [carrierIdParam, setCarrierIdParam] = useState<string | null>(null);
  const [planIdParam, setPlanIdParam] = useState<string | null>(null);

  useEffect(() => {
    const companyName = searchParams.get('name');
    const carrierId = searchParams.get('carrier_id');
    const planId = searchParams.get('plan_id');

    let urlParams: UrlParams = {};

    if (companyName) {
      setCompanyNameParam(companyName);
      urlParams.name = companyName;
    }

    if (carrierId) {
      setCarrierIdParam(carrierId);
      urlParams.carrier_id = carrierId;
    }

    if (planId) {
      setPlanIdParam(planId);
      urlParams.plan_id = planId;
    }

    if (Object.keys(urlParams).length) {
      console.log(urlParams);

      localStorage.setItem(
        'urlParams',
        JSON.stringify(urlParams),
      );
    }
  }, [searchParams]);

  return companyNameParam || carrierIdParam || planIdParam
    ? {
      name: companyNameParam,
      carrier_id: carrierIdParam,
      plan_id: planIdParam,
    }
    : undefined;
}

export default useGetUrlParams;
