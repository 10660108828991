import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack } from '@mui/material';
import axios from 'axios';
import { setIn } from 'formik';
import { FC } from 'react';
import { apiClient, CreateIntegrationRequest } from '../../../api';
import { AmazonRelayForm, useAmazonRelayForm } from './AmazonRelayForm';
import { WizardPopupAction, WizardState } from './reducer';
import { StepHeader } from './StepHeader';

export interface AmazonRelayStepProps {
  wizardState: WizardState;
  dispatch: React.Dispatch<WizardPopupAction>;
  onBack?: (createIntegrationReq?: CreateIntegrationRequest) => void;
  onNext: (createIntegrationReq: CreateIntegrationRequest) => void;
}

export const AmazonRelayStep: FC<AmazonRelayStepProps> = ({
  wizardState,
  dispatch,
  onBack,
  onNext,
}) => {
  const amazonRelayForm = useAmazonRelayForm({
    initialValues: wizardState.createIntegrationReq as CreateIntegrationRequest,
    isInitialValid: false,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { setTouched, setErrors }) => {
      try {
        if (wizardState.initialLegendId) {
          await apiClient.updateIntegration({
            id: wizardState.initialLegendId,
            settings: values.settings,
          });
          return onNext(values);
        }
        let entity = wizardState.createIntegrationReq.entity;
        if (wizardState.createCompanyReq) {
          const newCompany = await apiClient.createCompany(wizardState.createCompanyReq);
          entity = { id: newCompany.id, name: 'Company' as const };
          dispatch({ type: 'COMPANY_CREATED', payload: newCompany });
        }
        await apiClient.createIntegration({
          ...values,
          entity: entity!,
        });
        onNext(values);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.data.fields) {
          const fields = err.response.data.fields;
          setTouched(
            Object.keys(fields).reduce((touched, key) => {
              return setIn(touched, key, true);
            }, {}),
          );
          setErrors(
            Object.keys(fields).reduce((errors, key) => {
              return setIn(errors, key, fields[key]?.[0]);
            }, {}),
          );
        }
      }
    },
  });
  return (
    <Stack
      flexGrow={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <StepHeader
        title="Amazon Relay Info"
        description={`Enter Amazon Relay credentials for ${wizardState.companyName}.`}
      />
      <Box className="step-body" flexGrow={1}>
        <AmazonRelayForm
          isUpdating={!!wizardState.initialLegendId}
          {...amazonRelayForm}
        />
      </Box>
      <Stack className="step-footer" flexDirection="row" justifyContent="space-between" mt={4}>
        <Button
          variant="outlined"
          startIcon={<ArrowBack />}
          onClick={() => onBack?.(amazonRelayForm.values)}
          disabled={!onBack}
        >
          Back
        </Button>
        <LoadingButton
          variant="contained"
          endIcon={<ArrowForward />}
          onClick={amazonRelayForm.submitForm}
          loading={amazonRelayForm.isSubmitting}
          disabled={!amazonRelayForm.isValid}
        >
          Connect
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
