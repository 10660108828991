import axios, { AxiosInstance, AxiosResponse } from 'axios';
import moment from 'moment';
import qs from 'qs';

import { setupAxios } from '../modules/auth';
import * as types from './types';

export class APIClient {
  constructor(public client: AxiosInstance) {
    client.defaults.paramsSerializer = (params: any) => qs.stringify(params, { arrayFormat: 'indices' });
    setupAxios(this.client);
  }

  async me(): Promise<types.Me> {
    const resp = await this.client.get('/v1/me');
    return resp.data.data;
  }

  async getConstants(): Promise<types.Constants> {
    const resp = await this.client.get('/v1/constants');
    return resp.data;
  }

  async listCompanies(req: types.ListCompaniesRequest): Promise<types.ListCompaniesResponse> {
    const resp = await this.client.get('/v1/companies', {
      params: req,
    });
    return resp.data;
  }

  async createCompany(req: types.CreateCompanyRequest): Promise<types.Company> {
    const resp = await this.client.post('/v1/companies', req);
    return resp.data.data;
  }

  async getCompany(req: types.GetCompanyRequest): Promise<types.Company> {
    const resp = await this.client.get(`/v1/companies/${req.id}`);
    return resp.data.data;
  }

  async createInstance(req: types.CreateInstanceRequest): Promise<types.Instance> {
    const resp = await this.client.post('/v1/instances', req);
    return resp.data;
  }

  async listAccessRules(req: types.ListAccessRulesRequest): Promise<types.ListAccessRulesResponse> {
    const resp = await this.client.get('/v1/access_rules', {
      params: req,
    });
    return resp.data;
  }

  async deleteAccessRule(req: types.DeleteAccessRulesRequest): Promise<void> {
    await this.client.delete(`/v1/access_rules/${req.id}`);
    return;
  }

  async listCarriers(req: types.ListCarriersRequest): Promise<types.ListCarriersResponse> {
    const resp = await this.client.get('/v1/carriers', {
      params: req,
    });
    return resp.data;
  }

  async trialContacts(req: types.TrialContactsRequest): Promise<void> {
    await this.client.put(`/v1/carriers/${req.carrierId}`, {
      contracts_trial_ends_at: moment().add(7, 'days').toDate(),
    });
    return;
  }

  async disableContracts(req: types.DisableContractsRequest): Promise<void> {
    await this.client.post('/v1/access_rules', {
      type: 'scac',
      value: req.scac,
      effect: 'deny',
      package: 'contracts',
    });
    return;
  }

  async freeLoad(req: types.FreeLoadRequest): Promise<void> {
    await this.client.post('/v1/access_rules', {
      type: 'scac',
      value: req.scac,
      effect: 'allow',
      package: 'loadboard',
    });
    return;
  }

  async disableLoad(req: types.DisableLoadRequest): Promise<void> {
    await this.client.post('/v1/access_rules', {
      type: 'scac',
      value: req.scac,
      effect: 'deny',
      package: 'loadboard',
    });
    return;
  }

  async trialLegend(req: types.TrialLegendRequest): Promise<void> {
    await this.client.put(`/v1/carriers/${req.carrierId}`, {
      legend_trial_ends_at: moment().add(7, 'days').toDate(),
    });
    return;
  }

  async listInstances(req: types.ListInstancesRequest): Promise<types.ListInstancesResponse> {
    const resp = await this.client.get('/v1/instances', {
      params: req,
    });
    return resp.data;
  }

  async getInstance(req: types.GetInstanceRequest): Promise<types.Instance> {
    const resp = await this.client.get(`/v1/instances/${req.id}`);
    return resp.data.data;
  }

  async updateInstance(req: types.UpdateInstanceRequest): Promise<types.Instance> {
    const { id, ...payload } = req;
    const resp = await this.client.put(`/v1/instances/${id}`, payload);
    return resp.data.data;
  }

  async terminalInstance(req: types.TerminalInstanceRequest): Promise<void> {
    await this.client.delete(`/v1/instances/${req.id}`);
    return;
  }

  async startInstance(req: types.StartInstanceRequest): Promise<void> {
    await this.client.get(`/v1/instances/${req.id}/start`);
    return;
  }

  async stopInstance(req: types.StopInstanceRequest): Promise<void> {
    await this.client.get(`/v1/instances/${req.id}/stop`);
    return;
  }

  async validateCaptcha(req: types.ValidateCaptchaRequest): Promise<void> {
    const { id, ...payload } = req;
    await this.client.post(`/v1/instances/${req.id}/captcha`, payload);
    return;
  }

  async validateOTP(req: types.ValidateOTPRequest): Promise<void> {
    const { id, ...payload } = req;
    await this.client.post(`/v1/instances/${req.id}/verification_code`, payload);
    return;
  }

  async getLoadBoardScreenshot(
    req: types.GetLoadBoardScreenshotRequest,
  ): Promise<types.GetLoadBoardScreenshotResponse> {
    const resp = await this.client.get(`/v1/instances/${req.id}/screenshot/loadboard`);
    return resp.data;
  }

  async getLoadBoardSourceCode(
    req: types.GetLoadBoardSourceCodeRequest,
  ): Promise<types.GetLoadBoardSourceCodeResponse> {
    const resp = await this.client.get(`/v1/instances/${req.id}/source_code/loadboard`);
    return resp.data;
  }

  async getUser(req: types.GetUserRequest): Promise<types.User> {
    const res = await this.client.get(`/v1/users/${req.id}`);
    return res.data.data;
  }

  async updatePermissions(req: types.UpdatePermissionsRequest): Promise<void> {
    await this.client.put(`/v1/acl/permissions`, req);
    return;
  }

  async listLoads(req: types.ListLoadsRequest): Promise<types.ListLoadsResponse> {
    const resp = await this.client.get('/v1/loads', {
      params: req,
    });
    return resp.data;
  }

  async listLocations(req: types.ListLocationsRequest): Promise<types.ListLocationsResponse> {
    const resp = await this.client.get('/v1/locations', {
      params: req,
    });
    return resp.data;
  }

  async listRequests(req: types.ListRequestsRequest): Promise<types.ListRequestsResponse> {
    const resp = await this.client.get('/v1/requests', {
      params: req,
    });
    return resp.data;
  }

  async createRequest(req: types.CreateRequestRequest): Promise<types.Request> {
    const resp = await this.client.post('/v1/requests', req);
    return resp.data;
  }

  async getRequest(req: types.GetRequestRequest): Promise<types.Request> {
    const resp = await this.client.get(`/v1/requests/${req.id}`);
    return resp.data.data;
  }

  async listLogs(req: types.ListLogsRequest): Promise<types.ListLogsResponse> {
    const resp = await this.client.get('/v1/requests/logs', {
      params: req,
    });
    return resp.data;
  }

  async createIntegration(req: types.CreateIntegrationRequest): Promise<unknown> {
    const resp = await this.client.post('/v1/integrations', req);
    return resp.data;
  }

  async updateIntegration(req: types.UpdateIntegrationRequest): Promise<unknown> {
    const { id, ...body } = req;
    const resp = await this.client.put(`/v1/integrations/${req.id}`, body);
    return resp.data;
  }

  //@todo need to refactor
  async updateIntegrationSettings(req: types.UpdateIntegrationSettingsRequest): Promise<unknown> {
    const { id, ...body } = req;
    const resp = await this.client.put(`/v1/integrations/${req.id}`, body);
    return resp.data;
  }

  async deleteIntegration(req: types.DeleteIntegrationRequest): Promise<void> {
    await this.client.delete(`/v1/integrations/${req.id}`);
    return;
  }

  async listFavoriteFilters(req: types.ListFavoriteFiltersRequest): Promise<types.ListFavoriteFiltersResponse> {
    const resp = await this.client.get('/v1/favorite_filters', {
      params: req,
    });
    return resp.data;
  }

  async deleteFavoriteFilter(req: types.DeleteFavoriteFilterRequest): Promise<void> {
    await this.client.delete(`/v1/favorite_filters/${req.id}`);
    return;
  }

  async stopRequest(req: types.StopRequestRequest): Promise<void> {
    await this.client.delete(`/v1/requests/${req.id}`);
    return;
  }

  async getStatistics(req: types.GetStatisticsRequest): Promise<types.Statistics> {
    const resp = await this.client.get('/v1/statistics', {
      params: req,
    });
    return resp.data;
  }

  async listDrivers(req: types.ListDriversRequest): Promise<types.ListDriversResponse> {
    const resp = await this.client.get(`/v1/companies/${req.companyId}/drivers`, {
      params: req,
    });
    return resp.data;
  }

  async checkRequests(req: types.CheckRequestsRequest): Promise<types.CheckRequestsResponse> {
    const resp = await this.client.post('/v1/requests/check', req);
    return resp.data;
  }
}

export const apiClient = new APIClient(axios.create({
  baseURL: process.env.REACT_APP_API_URL,
}));
