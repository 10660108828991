import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect } from 'react';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';

import { apiClient, ListRequestsRequest } from '../../api';

export interface RequestSelectorProps {
  company_id?: string;
  value?: string[];
  label?: string;
  onChange: (ids: string[]) => void;
  error?: boolean;
  helperText?: string;
}

export const RequestSelector: FC<RequestSelectorProps> = ({
  company_id,
  value,
  onChange,
}) => {
  const [request, setRequest] = useState<ListRequestsRequest>({
    page: 1,
    per_page: 20,
    company_id,
  });

  useEffect(() => {
    if (company_id !== request.company_id) {
      setRequest({ ...request, company_id });
    }
  }, [company_id]);

  const listRequestsQuery = useQuery({
    enabled: !!company_id,
    queryKey: [`requests`, request],
    queryFn: () => apiClient.listRequests(request),
    suspense: false,
  });

  return (
    <FormControl fullWidth size="small">
      <InputLabel>Select requests...</InputLabel>
      <Select
        label="Requests"
        value={value}
        multiple
        disabled={!company_id}
        placeholder="Select requests"
        onChange={({ target }) => {
          onChange(target.value as string[]);
        }}
        fullWidth
        size="small"
      >
        {listRequestsQuery.data?.data.map(req => (
          <MenuItem key={req.id} value={req.id}>
            {`${req.number} - ${req.status.name}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
