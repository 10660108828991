import { QueryClient } from 'react-query';
import { apiClient } from '../api';

export function prefetch(queryClient: QueryClient) {
  queryClient.prefetchQuery({
    queryKey: ['constants'],
    queryFn: () => apiClient.getConstants(),
  });
  queryClient.prefetchQuery({
    queryKey: ['constants'],
    queryFn: () => apiClient.listCompanies({ page: 1 }),
  });
}
