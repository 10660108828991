import { FC } from 'react';
import { IconProps } from './common';

export const CalendarDaily: FC<IconProps> = ({ size = 16 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentcolor"
        fillRule="evenodd"
        d="M4.25 9v7.91c0 1.46.402 2.345 1 2.886.621.563 1.672.954 3.372.954h6.747c1.705 0 2.759-.392 3.38-.954.6-.541 1.001-1.425 1.001-2.886V9a.75.75 0 00-.75-.75H5a.75.75 0 00-.75.75zM3 9a2 2 0 012-2h14a2 2 0 012 2v7.91c0 3.382-1.888 5.09-5.63 5.09H8.621C4.888 22 3 20.292 3 16.91V9z"
        clipRule="evenodd"
      >
      </path>
      <path
        fill="currentcolor"
        d="M7.55 3.717V3.71l8.91.004c.48.044.954.138 1.417.282v-.007c.026.011.046.018.08.03l.005.002.046.017.062.023c.095.03.183.067.274.105l.016.007c.043.015.082.033.118.05l.033.015a3.153 3.153 0 01.334.17l.058.034c.031.017.058.035.084.052l.055.037c.26.167.501.363.72.582l.008.014c.08.08.157.166.228.256l.006.013c.7.969 1.05 2.163.989 3.373v.387H3.007v-.384c-.061-1.21.29-2.403.992-3.371l.013-.013c.044-.053.09-.105.137-.159l.084-.097.013-.013c.216-.222.456-.417.714-.583l.055-.035c.03-.019.06-.038.09-.054l.172-.099.108-.052.107-.053.076-.032a3.843 3.843 0 01.374-.145.518.518 0 01.107-.04.37.37 0 01.046-.018c.013-.004.026-.008.04-.014v.005a6.874 6.874 0 011.415-.282z"
      >
      </path>
      <path
        fill="currentcolor"
        d="M16.5 2.717v3.151a.72.72 0 01-1.44 0V2.724a.72.72 0 011.44-.01v.003zM8.94 2.728v3.144a.72.72 0 11-1.44 0V2.728a.72.72 0 111.44 0z"
      >
      </path>
      <path
        fill="currentcolor"
        d="M8.99 12.474H7.687v-1.179h2.7V18H8.99v-5.526zM11.556 16.92l1.8-1.854.288-.297c.276-.294.483-.528.621-.702.144-.174.243-.321.297-.441a.974.974 0 00.081-.387.733.733 0 00-.243-.576.842.842 0 00-.594-.216 1.08 1.08 0 00-.666.216c-.186.144-.324.357-.414.639l-1.215-.396c.072-.342.22-.642.441-.9a2.38 2.38 0 01.828-.603 2.498 2.498 0 011.026-.216c.438 0 .825.078 1.161.234.342.156.606.378.792.666.186.282.28.609.28.981 0 .24-.043.474-.127.702a3.11 3.11 0 01-.378.702c-.168.234-.39.492-.666.774L13.33 16.83h2.772V18h-4.545v-1.08z"
      >
      </path>
    </svg>
  );
};
