import { IconButton, List, ListItem, ListItemText, Popover } from '@mui/material';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { FC } from 'react';

import { RotateCcw } from 'react-feather';
import { History } from '../../api';
import { DateFormatter } from '../../components/format';

export interface HistoryListProps {
  history: History[];
}

export const HistoryPopup: FC<HistoryListProps> = ({ history }) => {
  return (
    <PopupState variant="popover">
      {popupState => {
        return (
          <div>
            <IconButton {...bindTrigger(popupState)}>
              <RotateCcw size={20} color="#426BB5" />
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <List dense>
                {history.map((item, i) => {
                  return (
                    <ListItem key={i} className="d-flex flex-column">
                      <ListItemText
                        primary={<DateFormatter value={item.created_at} />}
                        secondary={
                          <div>
                            {item.payout.symbol}
                            {item.payout.value / 100}{' '}
                            {(item.payout?.value / item.total_distance.value).toFixed(2)}/{item.total_distance
                              ?.symbol}
                          </div>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Popover>
          </div>
        );
      }}
    </PopupState>
  );
};
