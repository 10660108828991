import { Autocomplete, Chip, TextField } from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { debounce } from 'lodash';
import { FC, useCallback, useState } from 'react';

import { apiClient, Location } from '../../api';

const optionBgColor: Record<string, string> = {
  'city': '#4C67B1',
  'facility': '#7FBCB2',
};

const getLabelFromLocation = (location: Location) => {
  if (location.type === 'city') {
    return `${location.name}, ${location.state_code}`;
  } else {
    return `Facility: ${location.name}`;
  }
};

export interface LocationSelectorProps {
  domain?: string;
  value: Location[];
  label?: string;
  onChange: (value: Location[]) => void;
  error?: boolean;
  helperText?: string;
  required?: boolean;
}

export const LocationSelector: FC<LocationSelectorProps> = ({
  label,
  domain,
  value = [],
  error,
  helperText,
  onChange,
  required,
}) => {
  const [options, setOptions] = useState<Location[]>([]);

  const debouncedLoadOptions = useCallback(
    // TODO(JuoCode): remove any
    debounce(async (domain: string, term: string, callback: (options: any[]) => void) => {
      if (!domain || !term) {
        return [];
      }
      const resp = await apiClient.listLocations({ domain, term });
      callback(resp.data);
    }, 300),
    [],
  );

  const loadLocations = useCallback((inputValue: string) => {
    debouncedLoadOptions(domain!, inputValue, (options: Location[]) => {
      setOptions(options);
    });
  }, [domain]);

  return (
    <Autocomplete
      multiple
      value={value}
      options={options}
      filterOptions={(x) => x}
      getOptionLabel={(option) => getLabelFromLocation(option)}
      onInputChange={(_, inputValue) => loadLocations(inputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          error={error}
          helperText={helperText}
          required={required}
        />
      )}
      onChange={(_, locations) => onChange(locations)}
      size="small"
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.display_name}
            size="small"
            color="primary"
            sx={{
              backgroundColor: optionBgColor[option.type],
            }}
            {...getTagProps({ index })}
          />
        ))}
    />
  );
};
