import { Box, styled, Typography } from '@mui/material';
import { FC } from 'react';

export interface StepHeaderProps {
  title: string;
  description?: string;
}

export const StepHeader: FC<StepHeaderProps> = ({ title, description }) => {
  return (
    <Box
      sx={{
        mt: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <Typography
        fontSize="18px"
        lineHeight="23px"
        fontWeight={800}
      >
        {title}
      </Typography>
      {description && (
        <SecondaryText>
          {description}
        </SecondaryText>
      )}
    </Box>
  );
};

const SecondaryText = styled('div')(({ theme }: any) => {
  return {
    color: theme.palette.mode === 'dark' ? '#BFD4E4' : '#597393',
    opacity: theme.palette.mode === 'dark' ? .6 : 1,
  };
});
