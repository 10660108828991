import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, FormControl, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import * as yup from 'yup';
import { CreateCompanyRequest } from '../../../api';
import { StepHeader } from './StepHeader';

const companyFormSchema = yup.object({
  name: yup.string().required('Company name is required'),
  carrier_id: yup.string().required('SCAC is required'),
});

export interface NewCompanyStepProps {
  initialValues?: CreateCompanyRequest;
  onBack: (values?: CreateCompanyRequest) => void;
  onNext: (newCompanyRequest: CreateCompanyRequest) => void;
}

export const NewCompanyStep: FC<NewCompanyStepProps> = ({
  onBack,
  onNext,
  initialValues = {
    name: '',
    carrier_id: '',
  },
}) => {
  const {
    handleSubmit,
    submitForm,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    handleChange,
  } = useFormik<CreateCompanyRequest>({
    validationSchema: companyFormSchema,
    isInitialValid: false,
    initialValues,
    onSubmit: (values) => onNext(values),
  });

  return (
    <Stack width="80%" mx="auto" flexGrow={1}>
      <StepHeader
        title="New Company Info"
        description="Enter below company info"
      />
      <Box flexGrow={1}>
        <form onSubmit={handleSubmit} noValidate>
          <Stack gap={2} mt={4}>
            <FormControl>
              <TextField
                fullWidth
                name="name"
                value={values.name}
                onChange={handleChange}
                label="Company Name"
                size="small"
                error={touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                required
              />
            </FormControl>
            <FormControl>
              <TextField
                fullWidth
                name="carrier_id"
                value={values.carrier_id}
                onChange={handleChange}
                label="Amazon Relay SCAC"
                size="small"
                error={touched.carrier_id && !!errors.carrier_id}
                helperText={touched.carrier_id && errors.carrier_id}
                required
              />
            </FormControl>
          </Stack>
        </form>
      </Box>
      <Stack
        className="step-footer"
        flexDirection="row"
        justifyContent="space-between"
        mt={4}
      >
        <Button
          variant="outlined"
          startIcon={<ArrowBack />}
          onClick={() => onBack(values)}
        >
          Back
        </Button>
        <LoadingButton
          variant="contained"
          endIcon={<ArrowForward />}
          type="submit"
          loading={isSubmitting}
          onClick={submitForm}
        >
          Continue
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
