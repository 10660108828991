import { Close } from '@mui/icons-material';
import { Box, CircularProgress, colors, DialogContent, IconButton, Stack, Step, StepIconProps, StepLabel, Stepper, useTheme } from '@mui/material';
import { createContext, FC, Suspense, useCallback, useContext, useMemo, useReducer } from 'react';
import { Check } from 'react-feather';
import { useQuery } from 'react-query';
import { apiClient, Company } from '../../api';
import { useSocket } from '../../hooks/useSocket';
import { AmazonRelayStep } from '../../pages/components/wizard/AmazonRelayStep';
import { CompanyStep } from '../../pages/components/wizard/CompanyStep';
import { ConnectStep } from '../../pages/components/wizard/ConnectStep';
import { NewCompanyStep } from '../../pages/components/wizard/NewCompanyStep';
import { defaultCreateIntegrationReq, WizardPopupAction, wizardReducer, WizardState } from '../../pages/components/wizard/reducer';
import { BootstrapDialog } from '../dialog';

type WizardPopupContext = {
  openWizardPopup: (payload: { company?: Company; legendId?: string }) => void;
  resetEvent: () => void;
};

export const wizardPopupCtx = createContext<WizardPopupContext>({
  openWizardPopup: () => {
    throw new Error('call openWizardPopup under WizardPopupProvider');
  },
  resetEvent: () => {
    throw new Error('call resetEvent under WizardPopupProvider');
  },
});

export const WizardPopupProvider: FC<{ children: React.ReactElement }> = ({ children }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const [state, dispatch] = useReducer(wizardReducer, {
    open: false,
    stepIndex: 0,
    createIntegrationReq: defaultCreateIntegrationReq,
  });
  const resetEvent = useCallback(() => {
    dispatch({ type: 'RESET_EVENT', payload: {} });
  }, []);

  const openWizardPopup = useCallback((payload: { company?: Company; legendId?: string }) => {
    dispatch({ type: 'OPEN', payload });
  }, []);

  // FIXME: selectedCompany is not reset
  const onClose = useCallback(() => {
    dispatch({ type: 'CLOSE', payload: {} });
  }, []);

  const onBack = useCallback(() => {
    dispatch({ type: 'BACK', payload: {} });
  }, []);

  const onNext = useCallback(() => {
    dispatch({ type: 'NEXT', payload: {} });
  }, []);

  const steps = useSteps({
    onClose,
    state,
    onNext,
    onBack,
    dispatch,
  });

  const handleSocketData = useCallback((event: any) => {
    switch (event.type) {
      case 'preparing':
      case 'authentication_failed':
      case 'captcha_required':
      case 'verification_required':
      case 'verification_code_required':
      case 'expired_trial_period':
        dispatch({ type: 'RECEIVE_EVENT', payload: event });
        return;
      case 'connected':
        if (state.open) {
          dispatch({ type: 'RECEIVE_EVENT', payload: event });
        }
        return;
      default:
        return <div>Unknown error, please contact the admin.({(event as any).type})</div>;
    }
  }, [state.open]);

  useSocket({
    onData: handleSocketData,
  });

  return (
    <wizardPopupCtx.Provider
      value={{
        openWizardPopup,
        resetEvent,
      }}
    >
      {children}
      <BootstrapDialog
        open={state.open}
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
          onClose();
        }}
        maxWidth="xl"
        sx={{
          '& .MuiDialog-paper': {
            width: '500px',
            borderRadius: '15px',
          },
          '& .MuiDialogContent-root': {
            padding: '25px 30px 20px',
          },
          // ...sx,
        }}
      >
        <DialogContent>
          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', top: 16, right: 16, zIndex: 1 }}
          >
            <Close />
          </IconButton>
          <Stack>
            <Stepper
              activeStep={state.stepIndex}
              alternativeLabel
              sx={{
                '.MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                  fontSize: '13px',
                  lineHeight: '24px',
                  fontWeight: 500,
                  marginTop: '5px',
                  color: isDark ? 'white' : '#4D4A4B',
                },
                '.MuiStepConnector-root': {
                  left: 'calc(-50% + 18px)',
                  right: 'calc(50% + 18px)',
                },
                '.MuiStepConnector-root.MuiStepConnector-horizontal': {
                  top: '18px',
                },
                '.MuiStepConnector-line': {
                  borderTopWidth: 2,
                },
                '.Mui-completed .MuiStepConnector-line': {
                  borderColor: colors.green[500],
                },
                '.Mui-active .MuiStepConnector-line': {
                  borderColor: colors.orange[500],
                },
              }}
            >
              {steps.map((step, i) => (
                <Step key={i} completed={state.stepIndex > i}>
                  <StepLabel StepIconComponent={StepIconComponent}>
                    {step.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            <Suspense fallback={<CircularProgress />}>
              {steps[state.stepIndex].render()}
            </Suspense>
          </Stack>
        </DialogContent>
      </BootstrapDialog>
    </wizardPopupCtx.Provider>
  );
};

export const useWizardPopup = () => useContext(wizardPopupCtx);

type UseStepsParams = {
  state: WizardState;
  legendId?: string;
  company?: Company;
  onClose: () => void;
  onNext: () => void;
  onBack: () => void;
  dispatch: React.Dispatch<WizardPopupAction>;
};

const useSteps = ({
  state,
  onClose,
  onNext,
  onBack,
  dispatch,
}: UseStepsParams) => {
  const listCompaniesQuery = useQuery({
    queryKey: ['companies'],
    queryFn: () => apiClient.listCompanies({ page: 1 }),
    suspense: false,
  });

  const steps = useMemo(() => {
    const companiesStep = {
      label: 'Company',
      render: () => {
        return (
          <CompanyStep
            value={state.selectedCompany}
            onBack={onBack}
            onNext={(company) => {
              if (company) {
                dispatch({ type: 'SELECTED_COMPANY', payload: company });
              } else {
                dispatch({ type: 'PENDING_CREATE_COMPANY' });
              }
              onNext();
            }}
          />
        );
      },
    };
    const amazonRelayStep = {
      label: 'Amazon Relay',
      render: () => (
        <AmazonRelayStep
          wizardState={state}
          dispatch={dispatch}
          // legendId={(state.event as any)?.legendId}
          onNext={(settings) => {
            dispatch({ type: 'AMAZON_RELAY_READY', payload: settings });
            onNext();
          }}
          onBack={(settings) => {
            if (settings) {
              dispatch({ type: 'AMAZON_RELAY_READY', payload: settings });
            }
            onBack();
          }}
        />
      ),
    };
    const connectStep = {
      label: 'Connect',
      render: () => (
        <ConnectStep
          wizardState={state}
          onFinish={onClose}
        />
      ),
    };
    const newCompanyStep = {
      label: 'New Company',
      render: () => (
        <NewCompanyStep
          initialValues={state.createCompanyReq}
          onBack={(formValues) => {
            if (formValues) {
              dispatch({ type: 'CANCEL_CREATE_COMPANY', payload: formValues });
            }
            onBack();
          }}
          onNext={(formValues) => {
            dispatch({ type: 'NEW_COMPANY_FILLED', payload: formValues });
            onNext();
          }}
        />
      ),
    };
    if (state.showConnectStepOnly) {
      return [connectStep];
    }
    if (state.initialCompany) {
      return [amazonRelayStep, connectStep];
    }

    const tabs: any[] = [];
    tabs.push(companiesStep);
    if (listCompaniesQuery.data && listCompaniesQuery.data.total === 0) {
      tabs.push(newCompanyStep);
    }
    tabs.push(amazonRelayStep, connectStep);
    return tabs;
  }, [
    state,
    listCompaniesQuery.data,
  ]);

  return steps;
};

const StepIconComponent: FC<StepIconProps> = ({
  active,
  completed,
  icon,
}) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const color = isDark ? '#fff' : '#4D4A4B';
  const borderColor = isDark ? '#757575' : '#BFD4E4';
  return (
    <Box
      sx={{
        width: '36px',
        height: '36px',
        border: active ? 'none' : completed ? `2px solid ${colors.green[600]}` : `2px solid ${borderColor}`,
        borderRadius: '36px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '16px',
        fontWeight: 700,
        backgroundColor: active ? colors.orange[500] : completed ? colors.green[500] : 'transparent',
        color: active ? '#fff' : completed ? 'white' : color,
      }}
    >
      {completed ? <Check size={16} /> : icon}
    </Box>
  );
};
