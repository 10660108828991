/* eslint-disable react/jsx-no-target-blank */
import {useState} from 'react';
import {useIntl} from 'react-intl';
import {useCompaniesHasLegendIntegration} from '../../../../app/hooks/useLegendIntegrations';
import {useAuth} from '../../../../app/modules/auth';
import {AsideMenuDownload} from './AsideMenuDownload';
import {AsideMenuItem} from './AsideMenuItem';

export function AsideMenuMain() {
    const authData = useAuth();
    const [isAdmin, setIsAdmin] = useState(authData.currentUser!.isAdmin.includes('admin'));
    const intl = useIntl();

    const adminMenus = (
        <>
            <AsideMenuItem
                icon="/media/icons/duotune/arrows/arr001.svg"
                to="/dashboard"
                title="Dashboard"
                fontIcon="bi-layers"
            />
            <AsideMenuItem
                icon="/media/icons/duotune/arrows/arr001.svg"
                to="/users"
                title="Users"
                fontIcon="bi-layers"
            />
            <AsideMenuItem
                icon="/media/icons/duotune/arrows/arr001.svg"
                to="/plans"
                title="Plans"
                fontIcon="bi-layers"
            />
            <AsideMenuItem
                icon="/media/icons/duotune/arrows/arr001.svg"
                to="/billing"
                title="Billing"
                fontIcon="bi-layers"
            />
            <AsideMenuItem
                icon="/media/icons/duotune/arrows/arr001.svg"
                to="/log"
                title="Active Log"
                fontIcon="bi-layers"
            />
            <AsideMenuItem
                icon="/media/icons/duotune/arrows/arr001.svg"
                to="/account"
                title="Account"
                fontIcon="bi-layers"
            />
            <AsideMenuItem
                icon="/media/icons/duotune/arrows/arr001.svg"
                to="/access-rules"
                title="Access Rules"
                fontIcon="bi-layers"
            />
            <AsideMenuItem
                icon="/media/icons/duotune/arrows/arr001.svg"
                to="/carriers"
                title="Carriers"
                fontIcon="bi-layers"
            />
            <AsideMenuItem
                icon="/media/icons/duotune/arrows/arr001.svg"
                to="/instances"
                title="Instances"
                fontIcon="bi-layers"
            />
            <AsideMenuItem
                icon="/media/icons/duotune/arrows/arr001.svg"
                to="/loads"
                title="Loads"
                fontIcon="bi-layers"
            />
            <AsideMenuItem
                icon="/media/icons/duotune/arrows/arr001.svg"
                to="/requests"
                title="Requests"
                fontIcon="bi-layers"
            />
            <AsideMenuItem
                icon="/media/icons/duotune/arrows/arr001.svg"
                to="/history"
                title="History"
                fontIcon="bi-layers"
            />
        </>
    );

    const companiesHasLegend = useCompaniesHasLegendIntegration();

    const userMenus = (
        <>
            <AsideMenuItem
                to="/dashboard"
                icon="/media/icons/duotune/arrows/arr001.svg"
                title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                fontIcon="bi-app-indicator"
            />
            <AsideMenuItem
                to="/account"
                icon="/media/icons/duotune/arrows/arr001.svg"
                title="Account"
                fontIcon="bi-layers"
            />

            <AsideMenuItem
                to="/companies/create"
                icon="/media/icons/duotune/arrows/arr001.svg"
                title="Subscribe Now"
                fontIcon="bi-layers"
                color="#0c8744"
            />
            <div className="menu-item">
                <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Extension
          </span>
                </div>
            </div>
            <AsideMenuItem
                to="/log"
                icon="/media/icons/duotune/arrows/arr001.svg"
                title="Activity Logs"
                fontIcon="bi-layers"
            />
            <AsideMenuDownload
                icon="/media/icons/duotune/files/fil021.svg"
                title="Download Extension"
                fontIcon="bi-layers"
            />
            <div className="menu-item">
                <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Legend
          </span>
                </div>
            </div>
            {companiesHasLegend.length > 0
                ? (
                    <>
                        <AsideMenuItem
                            to="/active-requests"
                            icon="/media/icons/duotune/arrows/arr001.svg"
                            title="Active Requests"
                            fontIcon="bi-layers"
                        />
                        <AsideMenuItem
                            to="/past-requests"
                            icon="/media/icons/duotune/arrows/arr001.svg"
                            title="Past Requests"
                            fontIcon="bi-layers"
                        />
                        <AsideMenuItem
                            to="/loads"
                            icon="/media/icons/duotune/arrows/arr001.svg"
                            title="Loads"
                            fontIcon="bi-layers"
                        />
                        <AsideMenuItem
                            to="/settings"
                            icon="/media/icons/duotune/arrows/arr001.svg"
                            title="Settings"
                            fontIcon="bi-layers"
                        />
                    </>
                )
                : (
                    <AsideMenuItem
                        to="/active-requests"
                        icon="/media/icons/duotune/arrows/arr001.svg"
                        title="Connect Amazon Relay"
                        fontIcon="bi-layers"
                        color="#0c8744"
                    />
                )}
        </>
    );

    return (
        <>
            <div className="menu-item">
                <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Menu
          </span>
                </div>
            </div>

            {isAdmin
                ? adminMenus
                : userMenus}
        </>
    );
}
