import { Autocomplete, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDebounce } from 'react-use';

import { apiClient, ListCompaniesRequest } from '../../api';

export interface CompanySelectorProps {
  value?: string;
  label?: string;
  onChange: (companyId?: string, companyDomainName?: string) => void;
  error?: boolean;
  helperText?: string;
}

export const CompanySelector: FC<CompanySelectorProps> = ({
  label,
  value,
  onChange,
  error,
  helperText,
}) => {
  const queryClient = useQueryClient();
  const [terms, setTerms] = useState('');
  const [request, setRequest] = useState<ListCompaniesRequest>({ page: 1, has_legend: 1 });

  useDebounce(
    () => setRequest(request => ({ ...request, terms })),
    300,
    [terms],
  );

  const getCompanyQuery = useQuery({
    queryKey: [`companies/${value}`],
    queryFn: () => apiClient.getCompany({ id: value! }),
    enabled: !!value,
    suspense: false,
  });

  const listCompaniesQuery = useQuery({
    queryKey: [`companies`, request],
    queryFn: () => apiClient.listCompanies(request),
    onSuccess: resp => {
      resp.data.forEach(item => {
        queryClient.setQueryData(`companies/${item.id}`, item);
      });
    },
    suspense: false,
  });

  return (
    <Autocomplete
      value={getCompanyQuery.data ?? null}
      options={listCompaniesQuery.data?.data ?? []}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.name}
      onInputChange={(_, inputValue) => setTerms(inputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Select company..."
          label={label}
          error={error}
          helperText={helperText}
          required
        />
      )}
      onChange={(_, company) => {
        let domainName = company ? company?.integrations[0].settings.domain.id : 'relay.amazon.com';
        onChange(company?.id, domainName);
      }}
      loading={listCompaniesQuery.isLoading}
      noOptionsText="No match, type to search"
      size="small"
      disablePortal
    />
  );
};
