import { Stack } from '@mui/material';
import { FC } from 'react';

export interface NonIdealStateProps {
  message?: string;
}

export const NonIdealState: FC<NonIdealStateProps> = ({
  message = 'There are no matches',
}) => {
  return (
    <Stack justifyContent="center" gap={3} alignItems="center" py={4}>
      <div className="lead text-muted">{message}</div>
      <img src="/media/svg/illustrations/easy/2.svg" width={200} />
    </Stack>
  );
};
