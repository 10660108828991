/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../app/modules/auth';

const UserMenu = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      {/* begin::Menu item */}
      <div className="menu-item px-5 my-1">
        <Link to="account" className="menu-link px-5">
          Account
        </Link>
      </div>
      {/* end::Menu item */}

      {/* begin::Menu item */}
      <div className="menu-item px-5">
        <a
          onClick={() => {
            logout();
            localStorage.setItem('isLogout', 'true');
            navigate('/');
          }}
          className="menu-link px-5"
        >
          Sign Out
        </a>
      </div>
    </div>
  );
};

export { UserMenu };
