import clsx from 'clsx';
import React from 'react';
import { KTSVG, WithChildren } from '../../../helpers';
import { useLayout } from '../../core';

type Props = {
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
};

const AsideMenuDownload: React.FC<Props & WithChildren> = ({
  children,
  title,
  icon,
  hasBullet = false,
}) => {
  const to = process.env.REACT_APP_EXTENSION_URL;
  const { config } = useLayout();
  const { aside } = config;

  return (
    <div className="menu-item">
      <a href={to} target="_blank" className={clsx('menu-link', { active: true })}>
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className="menu-icon">
            <KTSVG path={icon} className="svg-icon-5" svgClassName=" " />
          </span>
        )}
        <span className="menu-title">{title}</span>
      </a>
      {children}
    </div>
  );
};

export { AsideMenuDownload };
