import moment from 'moment-timezone';
import { FC, useMemo } from 'react';

export interface DateFormatterProps {
  value: string | Date | null;
  format?: string;
  tz?: string;
}

export const DateFormatter: FC<DateFormatterProps> = ({
  value,
  format = 'MM/DD/YYYY hh:mm a',
  tz,
}) => {
  if (!value) {
    return null;
  }
  let m = moment(value);
  if (tz) {
    m = m.tz(tz);
  }
  return <span>{m.format(format)}</span>;
};

export interface DurationFormatterProps {
  start: Date | string;
  end: Date | string;
}

export const DurationFormatter: FC<DurationFormatterProps> = ({
  start,
  end,
}) => {
  const durationObj = moment.duration(new Date(end).getTime() - new Date(start).getTime());
  const days = durationObj.days();
  const hours = durationObj.hours();
  const mins = durationObj.minutes();
  let str = '';
  if (days > 0) {
    str += `${days}d `;
  }
  if (hours > 0) {
    str += `${hours}h `;
  }
  if (mins > 0) {
    str += `${mins}m`;
  }
  return <span>{str}</span>;
};

export function capitalized(str: string) {
  var arr = str.toLowerCase().split(' ');
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(' ');
}

export const currencyUnitToLocale: Record<string, string> = {
  USD: 'en-US',
  EUR: 'en-EU',
  GBP: 'en-GB',
};

export const CurrencyFormat: FC<{ value: number; unit: string }> = ({ value, unit }) => {
  const formatter = useMemo(() => {
    return new Intl.NumberFormat(currencyUnitToLocale[unit] || 'en-US', {
      style: 'currency',
      currency: unit,
    });
  }, [unit]);
  return <span>{formatter.format(value)}</span>;
};
