import pubsub from 'pubsub-js';
import { useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
import { apiClient } from '../api';

const DEFAULT_TOPIC = 'default_topic';
const BROADCAST_URL: string = process.env.REACT_APP_BROADCAST_URL || '';

let socket: Socket | undefined;

function connect() {
  if (socket) {
    return;
  }
  socket = io(BROADCAST_URL);
  socket.on('connect', async () => {
    let cid: string;
    try {
      const me = await apiClient.me();
      cid = me.channel_id;
    } catch (err) {
      console.error('get channel id failed:', err);
      return;
    }
    socket?.emit('join', cid);
    socket?.on('broadcast', onBroadcast);
  });
  socket.on('connect_error', (error) => {
    console.warn(`connect_error: ${error.message}`);
  });
  return socket;
}

function onBroadcast(data: string) {
  try {
    pubsub.publish(DEFAULT_TOPIC, JSON.parse(data));
  } catch (err) {
    console.error(`parse json from broadcast failed`, err);
  }
}

export interface UseSocketProps {
  onData: (data: any) => void;
}

export const useSocket = (props: UseSocketProps) => {
  useEffect(() => {
    connect();
    const token = pubsub.subscribe(DEFAULT_TOPIC, (msg, data) => {
      props.onData(data);
    });
    return () => {
      pubsub.unsubscribe(token);
      // TODO(JuoCode): disconnect when no subscribers
    };
  }, [props.onData]);
  return null;
};

// const emit = (data: any) => {
//   pubsub.publish(DEFAULT_TOPIC, data);
// };

// (window as any).emitAuthenticationFailed = () => {
//   const event = {
//     type: 'authentication_failed',
//     integration_id: 'fake-integration-id',
//   };
//   console.debug(`emit: `, event);
//   emit(event);
// };

// (window as any).emitCaptchaRequired = () => {
//   const event = {
//     type: 'captcha_required',
//     captcha: 'fake-captcha',
//     instance_id: 'fake-instance-id',
//   };
//   console.debug(`emit: `, event);
//   emit(event);
// };

// (window as any).emitConnected = () => {
//   const event = {
//     'type': 'connected',
//     'ip_address': '{ip_address_of_instance}',
//     'carrier_scac': 'AMDDA',
//     'carrier_name': 'Transporation LLC',
//   };
//   console.debug(`emit: `, event);
//   emit(event);
// };
