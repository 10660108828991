import { Box, MenuItem, Pagination, Select, SelectChangeEvent } from '@mui/material';
import * as React from 'react';

const pageSizeOpts = [5, 10, 20, 30, 50, 100];

export interface PaginationProps {
  current_page: number;
  total: number;
  per_page: number;
  onPageChange?: (value: { page: number; per_page: number }) => void;
}

export const PaginationBar: React.FC<PaginationProps> = ({
  current_page = 1,
  total,
  per_page = 10,
  onPageChange,
}) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Pagination
        count={Math.ceil(total / per_page)}
        onChange={(_, page) => onPageChange?.({ page, per_page })}
      />
      <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <Select
          size="small"
          value={per_page.toString()}
          onChange={(evt: SelectChangeEvent) => {
            onPageChange?.({ page: 1, per_page: parseInt(evt.target.value) });
          }}
        >
          {pageSizeOpts.map(size => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </Select>
        <span>Showing {(current_page - 1) * per_page + 1}-{Math.min(current_page * per_page, total)} of {total}</span>
      </Box>
    </Box>
  );
};
