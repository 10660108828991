import { FC } from 'react';
import { IconProps } from './common';

export const CalendarAnnual: FC<IconProps> = ({ size = 16 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 49 48"
    >
      <path
        fill="currentcolor"
        fillRule="evenodd"
        d="M9.167 18v15.82c0 2.921.803 4.69 2 5.773 1.242 1.124 3.344 1.907 6.744 1.907h13.494c3.41 0 5.517-.783 6.761-1.908 1.197-1.082 2-2.85 2-5.771V18a1.5 1.5 0 00-1.5-1.5h-28a1.5 1.5 0 00-1.5 1.5zm-2.5 0a4 4 0 014-4h28a4 4 0 014 4v15.82c0 6.763-3.777 10.18-11.262 10.18H17.91c-7.467 0-11.244-3.417-11.244-10.18V18z"
        clipRule="evenodd"
      >
      </path>
      <path
        fill="currentcolor"
        d="M15.766 7.434V7.42l17.822.007c.959.089 1.907.278 2.832.564v-.014c.052.024.092.038.16.062l.012.004.09.033c.04.016.08.03.124.045.19.062.367.136.55.211l.032.014c.084.03.163.066.235.099l.067.03c.15.066.305.145.428.21.083.043.163.089.238.132l.118.066c.061.034.115.07.166.105a8.657 8.657 0 011.551 1.239l.015.027c.162.162.314.332.457.512l.012.025a10.604 10.604 0 011.978 6.746v.775H6.68v-.768a10.596 10.596 0 011.985-6.742l.026-.025c.087-.107.18-.212.273-.319a19.1 19.1 0 00.17-.194l.025-.026a8.194 8.194 0 011.537-1.235c.06-.039.119-.077.182-.11l.344-.197.214-.105.214-.105c.051-.02.102-.042.152-.065s.101-.045.152-.065c.19-.08.393-.158.595-.224.069-.034.141-.06.215-.078a.737.737 0 01.093-.037c.025-.009.05-.018.078-.03v.011a13.748 13.748 0 012.831-.564z"
      >
      </path>
      <path
        fill="currentcolor"
        d="M33.667 5.434v6.303c0 .794-.645 1.437-1.44 1.437-.796 0-1.44-.643-1.44-1.437V5.448A1.437 1.437 0 0132.217 4a1.438 1.438 0 011.45 1.427v.007zM18.547 5.457v6.287a1.441 1.441 0 11-2.88 0V5.457a1.44 1.44 0 112.88 0z"
      >
      </path>
    </svg>
  );
};
