/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import 'react-toastify/dist/ReactToastify.css';

import { useInView } from 'react-intersection-observer';
import { toast, ToastContainer } from 'react-toastify';
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components';
import { useThemeMode } from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider';
import getTheme from '../../../helpers/getTheme';
import notify from '../../../helpers/notify';
import { checkEmailCode, getUserByToken, register, sendCodeToEmail, signIn, validateUser } from '../core/_requests';
import { useAuth } from '../core/Auth';
import { TermsAndConditions } from './TermsAndConditions';

const initialValues = {
  name: '',
  email: '',
  password: '',
  changepassword: '',
};

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        "Password and Confirm Password didn't match",
      ),
    }),
});

export function Registration() {
  const themeMode = useThemeMode();
  const navigate = useNavigate();
  const [verification, setVerification] = useState<string | null>(''); // null
  const [verificationCode, setVerificationCode] = useState(''); // ''
  const [verificationIsOk, setVerificationIsOk] = useState(false); // false
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { saveAuth, setCurrentUser } = useAuth();
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      validateUser(values.email, values.password)
        .then((r) => {
          setVerification(values.email);
          setLoading(false);
          setError('');
        })
        .catch((err) => {
          const res = err.response;
          console.log('status: ', res.status, res.data.message);
          setError(res.data.message);
          saveAuth(undefined);
          setStatus('The registration details is incorrect');
          setSubmitting(false);
          setLoading(false);
        });
    },
  });

  const verificateEmail = () => {
    checkEmailCode(formik.values.email, verificationCode)
      .then((r) => {
        setVerificationIsOk(true);
        setError('');
      })
      .catch((err) => {
        const res = err.response;
        console.log('status: ', res.status, res.data.message);
        setError(res.data.message);
      });
    // if(isChecked) {
    //   setVerificationIsOk(true);
    //   setError(false)
    //   return
    // }
    // setError(true)
  };

  const userSignUp = async () => {
    const params = localStorage.getItem('urlParams');
    setLoading(true);
    const { name, email, password } = formik.values;
    await register(email, name, password, verificationCode);
    signIn(email, password)
      .then((r) => {
        const data = r.data;
        const api_token = data.token;
        saveAuth({ api_token });
        getUserByToken(api_token).then((r) => {
          setCurrentUser(r);
          if (params) {
            const { name, carrier_id, plan_id } = JSON.parse(params);
            localStorage.removeItem('urlParams');
            navigate(
              `/companies/create?name=${name}&carrier_id=${carrier_id}&plan_id=${plan_id}`,
            );
          }
        });
        setError('');
        setLoading(false);
      })
      .catch((err) => {
        const res = err.response;
        console.log('status: ', res.status, res.data.message);
        setError(res.data.message);
        saveAuth(undefined);

        setLoading(false);
      });
  };

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  useEffect(() => {
    if (inView) setTermsAndConditions(true);
  }, [inView]);

  const onResend = async () => {
    console.log('resend');
    notify(
      'Confirmation email was sent.',
      'success',
      getTheme(themeMode.mode),
    );
    await sendCodeToEmail(formik.values.email);
  };

  return verification
    ? (
      verificationIsOk
        ? (
          // Terms & Conditions - start
          <div className="">
            <div className="d-flex flex-column">
              <h2 className="text-dark mb-5">Terms and conditions</h2>
              <div
                style={{ height: '200px' }}
                className="mb-5 border border-dark rounded p-4 overflow-auto "
              >
                <div dangerouslySetInnerHTML={{ __html: TermsAndConditions }}>
                </div>
                <span ref={ref}>{inView}</span>
              </div>
            </div>
            <button
              type="button"
              onClick={userSignUp}
              id="kt_sign_up_submit"
              className="btn btn-lg btn-primary w-100"
              disabled={!termsAndConditions}
            >
              {!loading && <span className="indicator-label">Agree and Sign Up</span>}
              {loading && (
                <span className="indicator-progress" style={{ display: 'block' }}>
                  Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        )
        : (
          // Terms & Conditions - end
          // verification code - start
          <div className="text-center">
            <p className="text-center fs-5 mb-5 text-dark">
              Please verify your email.
              <br />
              We have sent you a one time passcode
            </p>
            <input
              placeholder="Enter code"
              type="text"
              autoComplete="off"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              className={clsx(
                'form-control form-control-lg fs-5 form-control-solid mb-5',
                {
                  'is-valid': verificationCode.length > 3,
                },
              )}
            />

            {error && (
              <div className="fv-plugins-message-container mb-5 text-center">
                <div className="fv-help-block">
                  <span role="alert">{error}</span>
                </div>
              </div>
            )}

            <button
              type="button"
              onClick={verificateEmail}
              id="kt_sign_up_submit"
              className="btn btn-lg btn-primary w-100 mb-5"
              disabled={verificationCode.length > 3 ? false : true}
            >
              {!loading && <span className="indicator-label">Continue</span>}
              {loading && (
                <span className="indicator-progress" style={{ display: 'block' }}>
                  Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
            <p className="text-center fs-5 mb-5 text-dark ">
              Didn't receive the email? Try checking
              <br />
              your junk or spam folders.
            </p>

            <p
              className="text-sm-start text-dark cursor-pointer"
              onClick={onResend}
            >
              Resend
            </p>
            <ToastContainer />
          </div>
        )
    )
    : (
      // verification code - end
      // form - start
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_signup_form"
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className="mb-10 text-center">
          {/* begin::Title */}
          <h1 className="text-dark mb-3">Create an Account</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className="text-gray-400 fw-bold fs-4">
            <Link
              to="/sign-in"
              className="link-primary fw-bolder"
              style={{ marginLeft: '5px' }}
            >
              Already have an account?
            </Link>
          </div>
          {/* end::Link */}
        </div>
        {/* end::Heading */}

        {/*{formik.status && (*/}
        {/*  <div className='mb-lg-15 alert alert-danger'>*/}
        {/*    <div className='alert-text font-weight-bold'>{formik.status}</div>*/}
        {/*  </div>*/}
        {/*)}*/}

        {/* begin::Form group name */}

        <div className="fv-row mb-7">
          <label className="form-label fw-bolder text-dark fs-6">Name</label>
          <input
            placeholder="Name"
            type="text"
            autoComplete="off"
            {...formik.getFieldProps('name')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.name && formik.errors.name,
              },
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              },
            )}
          />
        </div>

        {/* end::Form group */}

        {/* begin::Form group Email */}
        <div className="fv-row mb-7">
          <label className="form-label fw-bolder text-dark fs-6">Email</label>
          <input
            placeholder="Email"
            type="email"
            autoComplete="off"
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              },
            )}
          />
        </div>
        {/* end::Form group */}

        {/* begin::Form group Password */}
        <div className="mb-7 fv-row" data-kt-password-meter="true">
          <div className="mb-1">
            <label className="form-label fw-bolder text-dark fs-6">
              Password
            </label>
            <div className="position-relative mb-3">
              <input
                type="password"
                placeholder="Password"
                autoComplete="off"
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  },
                )}
              />
            </div>
            {/* begin::Meter */}
            <div
              className="d-flex align-items-center mb-3"
              data-kt-password-meter-control="highlight"
            >
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
            </div>
            {/* end::Meter */}
          </div>
          <div className="text-muted">
            Use 8 or more characters with a mix of letters, numbers & symbols.
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className="fv-row mb-7">
          <label className="form-label fw-bolder text-dark fs-6">
            Confirm Password
          </label>
          <input
            type="password"
            placeholder="Password confirmation"
            autoComplete="off"
            {...formik.getFieldProps('changepassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
              },
            )}
          />
        </div>

        {formik.touched.name && formik.errors.name && (
          <div className="fv-plugins-message-container mb-5 text-center">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.name}</span>
            </div>
          </div>
        )}

        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container mb-5 text-center">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}

        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container mb-5 text-center">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}

        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className="fv-plugins-message-container mb-5 text-center">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.changepassword}</span>
            </div>
          </div>
        )}

        {error && (
          <div className="fv-plugins-message-container mb-5 text-center">
            <div className="fv-help-block">
              <span role="alert">{error}</span>
            </div>
          </div>
        )}

        {/* end::Form group */}

        {/* begin::Form group */}
        {/*cut*/}
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="text-center">
          <button
            type="submit"
            id="kt_sign_up_submit"
            className="btn btn-lg btn-primary w-100 mb-7"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className="indicator-label">Continue</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: 'block' }}>
                Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <Link to="/sign-in">
            <button
              type="button"
              id="kt_login_signup_form_cancel_button"
              className="btn btn-lg btn-light-primary w-100 mb-5"
            >
              Cancel
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    );
}
