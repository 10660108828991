import { Add, ArrowBack, ArrowForward, Business } from '@mui/icons-material';
import { Box, Button, colors, List, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { Check } from 'react-feather';
import { useQuery } from 'react-query';
import { apiClient, Company } from '../../../api';
import { StepHeader } from './StepHeader';

export interface CompanyStepProps {
  value?: Company;
  onBack: () => void;
  onNext: (company: Company | undefined) => void;
}

export const CompanyStep: FC<CompanyStepProps> = ({ value, onBack, onNext }) => {
  const listCompaniesQuery = useQuery({
    queryKey: ['companies'],
    queryFn: () => apiClient.listCompanies({ page: 1 }),
  });
  const [selected, setSelected] = useState<Company | undefined>(value);
  const [useNewCompany, setUseNewCompany] = useState<boolean>(false);
  return (
    <Stack
      flexGrow={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <StepHeader
        title="Company Info"
        description="Create a company or select an existing company if you are a returning customer."
      />
      <Box className="step-body" flexGrow={1}>
        <Typography
          fontSize="16px"
          lineHeight="20px"
          fontWeight={700}
        >
          Select Company
        </Typography>
        <List>
          {listCompaniesQuery.data?.data
            .filter(company => company.integrations.findIndex(i => i.type === 'legend') === -1)
            .map(company => {
              return (
                <ListItem key={company.id} disablePadding>
                  <ListItemButton
                    selected={selected?.id === company.id}
                    onClick={() => {
                      setUseNewCompany(false);
                      setSelected(company);
                    }}
                  >
                    <ListItemIcon>
                      <Business color="warning" />
                    </ListItemIcon>
                    <ListItemText
                      primary={company.name}
                      primaryTypographyProps={{
                        sx: {
                          lineHeight: '20px',
                          fontWeight: 500,
                        },
                      }}
                    />
                    {selected?.id === company.id && (
                      <ListItemSecondaryAction>
                        <Check color={colors.green[300]} />
                      </ListItemSecondaryAction>
                    )}
                  </ListItemButton>
                </ListItem>
              );
            })}
          {/* TODO(JuoCode): show "load more" button */}
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setUseNewCompany(true);
                setSelected(undefined);
              }}
              selected={useNewCompany}
            >
              <ListItemIcon>
                <Add />
              </ListItemIcon>
              <ListItemText primary="New Company" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Stack
        className="step-footer"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Button
          variant="outlined"
          startIcon={<ArrowBack />}
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          variant="contained"
          endIcon={<ArrowForward />}
          onClick={() => onNext(selected)}
          disabled={useNewCompany ? false : !selected}
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  );
};
