import styled from '@emotion/styled';
import { Autocomplete, Avatar, Box, Chip, CircularProgress, colors, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { FC, Suspense, useEffect, useMemo, useState } from 'react';
import { ArrowRight } from 'react-feather';
import { TwoUsers, User } from 'react-iconly';
import { useQuery } from 'react-query';
import { CellProps, Column } from 'react-table';
import { useDebounce } from 'react-use';

import { PageTitle } from '../../../_metronic/layout/core';
import { apiClient, ListLoadsRequest, Load } from '../../api';
import { capitalized, DateFormatter, DurationFormatter } from '../../components/format';
import { Table } from '../../components/table';
import { clearableProps } from '../../helpers/selectProps';
import { useConstants } from '../../hooks/useConstants';
import { HistoryPopup } from '../components/HistoryPopup';
import { LocationSelector } from '../components/LocationSelector';
import { RequestCheckPopup } from '../components/RequestCheckPopup';

// TODO(JuoCode): refactor to a shared compact table styles
import './Loads.scss';

type Filter =
  & Omit<ListLoadsRequest, 'additional_filters' | 'equipment_types'>
  & {
    additional_filters: { id: string; name: string }[];
    equipment_types: { id: string; name: string }[];
  };

const LoadsPage: FC = () => {
  const [request, setRequest] = useState<ListLoadsRequest>({ page: 1, domain: '', per_page: 10 });
  const [filter, setFilter] = useState<Partial<Filter>>({});

  const useConstantsQuery = useConstants({
    suspense: false,
  });

  useDebounce(
    () => {
      const {
        page = 1,
        work_types,
        equipment_types,
        additional_filters,
        start_date_time,
        end_date_time,
        ...others
      } = filter;
      setRequest({
        ...others,
        page,
        domain: filter.domain!,
        equipment_types: equipment_types?.map(equipment => equipment.id),
        additional_filters: additional_filters?.map(filter => filter.id),
        start_date_time: start_date_time ?? undefined,
        end_date_time: end_date_time ?? undefined,
      });
    },
    300,
    [filter],
  );

  const selectedDomain = useMemo(() => {
    return useConstantsQuery.data?.domains.find(domain => domain.id === filter.domain);
  }, [useConstantsQuery.data, filter.domain]);

  useEffect(() => {
    if (useConstantsQuery.data) {
      setFilter({ domain: useConstantsQuery.data.domains[0].id, per_page: 10 });
    }
  }, [useConstantsQuery.data]);

  return (
    <>
      <PageTitle>Loads</PageTitle>
      <div className="card card-flush">
        <div className="card-header py-8" style={{ display: 'block' }}>
          <Grid container columnSpacing={1} rowSpacing={1.5}>
            <Grid item xs={6} lg={3}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel>Domain</InputLabel>
                <Select
                  value={filter.domain ?? ''}
                  label="Domain"
                  onChange={({ target }) => {
                    setFilter({ domain: target.value });
                  }}
                >
                  {useConstantsQuery.data?.domains.map(domain => (
                    <MenuItem key={domain.id} value={domain.name}>{domain.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} lg={3}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel>Work Type</InputLabel>
                <Select
                  label="Work Type"
                  value={filter.work_types ?? []}
                  multiple
                  onChange={({ target }) => {
                    setFilter({ ...filter, work_types: target.value as string[] });
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map(id => {
                        const { name } = selectedDomain?.dependencies.work_types.find(wt => wt.id === id)!;
                        return <Chip key={id} label={name} size="small" />;
                      })}
                    </Box>
                  )}
                >
                  {selectedDomain?.dependencies.work_types.map(wt => (
                    <MenuItem key={wt.id} value={wt.id}>{wt.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={9} lg={5}>
              <FormControl fullWidth variant="outlined" size="small">
                <LocationSelector
                  label="Origins"
                  domain={filter.domain}
                  value={filter.origins ?? []}
                  onChange={(value) => setFilter({ ...filter, origins: value })}
                />
              </FormControl>
            </Grid>

            <Grid item xs={3} lg={1}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel>Radius</InputLabel>
                <Select
                  label="Origin Radius"
                  value={filter.origins_radius ?? ''}
                  onChange={({ target }) => setFilter({ ...filter, origins_radius: target.value as number })}
                  {...clearableProps({
                    value: filter.origins_radius,
                    onClear: () => setFilter({ ...filter, origins_radius: undefined }),
                  })}
                >
                  {selectedDomain?.dependencies.origins_radius.map(wt => (
                    <MenuItem key={wt.id} value={wt.name}>{wt.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={9} lg={5}>
              <FormControl fullWidth variant="outlined" size="small">
                <LocationSelector
                  label="Destination"
                  domain={filter.domain}
                  value={filter.destinations ?? []}
                  onChange={(value) => setFilter({ ...filter, destinations: value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3} lg={1}>
              <FormControl fullWidth variant="outlined" size="small" hiddenLabel>
                <InputLabel>Radius</InputLabel>
                <Select
                  label="Radius"
                  value={filter.destinations_radius ?? ''}
                  onChange={({ target }) => setFilter({ ...filter, destinations_radius: target.value as number })}
                  {...clearableProps({
                    value: filter.destinations_radius,
                    onClear: () => setFilter({ ...filter, destinations_radius: undefined }),
                  })}
                >
                  {selectedDomain?.dependencies.destinations_radius.map(wt => (
                    <MenuItem key={wt.id} value={wt.name}>{wt.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <FormControl fullWidth variant="outlined" size="small">
                <Autocomplete
                  multiple
                  value={filter.equipment_types ?? []}
                  getOptionLabel={(option) => option.name}
                  options={selectedDomain?.dependencies.equipments ?? []}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Equipment" />}
                  onChange={(_, value) => setFilter({ ...filter, equipment_types: value })}
                  size="small"
                />
              </FormControl>
            </Grid>

            <Grid item xs={4} lg={1}>
              <FormControl fullWidth variant="outlined" size="small">
                <TextField
                  label="Price"
                  type="number"
                  size="small"
                  variant="outlined"
                  value={filter.min_price_per_distance}
                  onChange={({ target }) => {
                    console.log(typeof target.value, getNumberValueOrUndefined(target as any));
                    setFilter({
                      ...filter,
                      min_price_per_distance: getNumberValueOrUndefined(target as HTMLInputElement),
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} lg={1}>
              <FormControl fullWidth variant="outlined" size="small">
                <TextField
                  label="Payout"
                  type="number"
                  size="small"
                  variant="outlined"
                  value={filter.min_payout}
                  onChange={({ target }) =>
                    setFilter({
                      ...filter,
                      min_payout: getNumberValueOrUndefined(target as HTMLInputElement),
                    })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} lg={1}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel>Max Stop</InputLabel>
                <Select
                  label="Max Stop"
                  value={filter.max_stops ?? ''}
                  onChange={({ target }) => {
                    setFilter({ ...filter, max_stops: target.value as number });
                  }}
                  {...clearableProps({
                    value: filter.max_stops,
                    onClear: () => setFilter({ ...filter, max_stops: undefined }),
                  })}
                >
                  {selectedDomain?.dependencies.stops.map(s => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth variant="outlined" size="small">
                <Autocomplete
                  multiple
                  value={filter.additional_filters ?? []}
                  getOptionLabel={(option) => option.name}
                  options={selectedDomain?.dependencies.additional_filters ?? []}
                  onChange={(_, value) => setFilter({ ...filter, additional_filters: value })}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Additional Filters" />}
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3} lg={1}>
              <FormControl fullWidth variant="outlined" size="small">
                <TextField
                  label="Min Trip Length"
                  size="small"
                  type="number"
                  value={filter.min_total_distance}
                  onChange={({ target }) => {
                    setFilter({ ...filter, min_total_distance: getNumberValueOrUndefined(target as HTMLInputElement) });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3} lg={1}>
              <FormControl fullWidth variant="outlined" size="small">
                <TextField
                  label="Max Trip Length"
                  size="small"
                  type="number"
                  value={filter.max_total_distance}
                  onChange={({ target }) => {
                    setFilter({ ...filter, max_total_distance: getNumberValueOrUndefined(target as HTMLInputElement) });
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6} md={6} lg={3}>
              <FormControl fullWidth variant="outlined" size="small">
                {/* TODO(JuoCode): determine how to show AM/PM in input */}
                {/* TODO(JuoCode): replace using mantine DatePicker */}
                <DateTimePicker<moment.Moment>
                  label="Start Date Time"
                  value={filter.start_date_time ? moment(filter.start_date_time) : null}
                  format="MM/DD/YYYY hh:mm a"
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'today', 'cancel', 'accept'],
                    },
                    textField: { size: 'small' },
                  }}
                  onAccept={(value) => {
                    setFilter({ ...filter, start_date_time: value ? value.toDate() : null });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6} lg={3}>
              <FormControl fullWidth variant="outlined" size="small">
                {/* TODO(JuoCode): determine how to show AM/PM in input */}
                {/* TODO(JuoCode): replace using mantine DatePicker */}
                <DateTimePicker<moment.Moment>
                  label="Start Date Time"
                  value={filter.end_date_time ? moment(filter.end_date_time) : null}
                  format="MM/DD/YYYY hh:mm a"
                  slotProps={{
                    actionBar: {
                      actions: ['clear', 'today', 'cancel', 'accept'],
                    },
                    textField: { size: 'small' },
                  }}
                  onAccept={(value) => {
                    setFilter({ ...filter, end_date_time: value ? value.toDate() : null });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <div className="card-body pt-0">
          {request.domain && (
            <Suspense fallback={<CircularProgress />}>
              <LoadsTable
                request={request}
                onRequestChange={setRequest}
              />
            </Suspense>
          )}
        </div>
      </div>
    </>
  );
};

interface LoadsTableProps {
  request: ListLoadsRequest;
  onRequestChange: (request: ListLoadsRequest) => void;
}

const SecondaryText = styled('div')(({ theme }: any) => {
  return {
    color: theme.palette.mode === 'dark' ? '#BFD4E4' : '#597393',
    opacity: theme.palette.mode === 'dark' ? .6 : 1,
    fontSize: '13px',
  };
});

const PrimaryText = styled('div')(({ theme }: any) => {
  return {
    color: theme.palette.mode === 'dark' ? '#ffffff' : '#151520',
    fontWeight: 500,
  };
});

export const LoadsTable: FC<LoadsTableProps> = ({ request, onRequestChange }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const columns = useMemo<Column<Load>[]>(() => [
    {
      Header: 'ID',
      Cell: ({ data, row }: CellProps<Load>) => {
        const { number } = data[row.index];
        return <Typography>{number}</Typography>;
      },
      sx: {
        padding: '15px 15px 15px 0',
      },
    },
    {
      Header: 'First Stop',
      Cell: () => {
        return (
          <Avatar
            sx={{
              width: 20,
              height: 20,
              bgcolor: '#F78C63',
              color: isDark ? '#191717' : 'white',
              fontSize: '16px',
              fontFamily: 'Work Sans',
            }}
          >
            1
          </Avatar>
        );
      },
      sx: {
        padding: '15px 15px 15px 0',
      },
    },
    {
      Header: 'Origin',
      Cell: ({ data, row }: CellProps<Load>) => {
        const { origin } = data[row.index];
        return (
          <Box sx={{ gap: '2px', display: 'flex', flexDirection: 'column' }}>
            <PrimaryText>{origin.location.title}</PrimaryText>
            <SecondaryText>
              <DateFormatter value={origin.time} tz={origin.time_zone} />
            </SecondaryText>
          </Box>
        );
      },
      sx: {
        padding: '15px 15px 15px 0',
      },
    },
    {
      Header: 'arrow',
      Cell: () => <ArrowRight size={20} color="#ffffff" opacity={.5} />,
      sx: {
        padding: '15px 15px 15px 0',
      },
    },
    {
      Header: 'Stop Count',
      Cell: ({ data, row }: CellProps<Load>) => {
        const { stops_count } = data[row.index];
        return (
          <Avatar
            sx={{
              width: 20,
              height: 20,
              bgcolor: '#F78C63',
              color: isDark ? '#191717' : 'white',
              fontSize: '16px',
              fontWeight: 500,
              fontFamily: 'Work Sans',
            }}
          >
            {stops_count.id}
          </Avatar>
        );
      },
      sx: {
        padding: '15px 15px 15px 0',
      },
    },
    {
      Header: 'Destination',
      Cell: ({ data, row }: CellProps<Load>) => {
        const { destination } = data[row.index];
        return (
          <div>
            <PrimaryText>{destination.location.title}</PrimaryText>
            <SecondaryText>
              <DateFormatter value={destination.time} tz={destination.time_zone} />
            </SecondaryText>
          </div>
        );
      },
      sx: {
        padding: '15px 15px 15px 0',
      },
    },
    {
      Header: 'col6',
      Cell: ({ data, row }: CellProps<Load>) => {
        const { total_distance, origin, destination } = data[row.index];
        return (
          <div>
            <PrimaryText className="text-nowrap">{total_distance.value / 100}/{total_distance.symbol}</PrimaryText>
            <SecondaryText>
              <DurationFormatter start={origin.time} end={destination.time} />
            </SecondaryText>
          </div>
        );
      },
      sx: {
        padding: '15px 15px 15px 0',
      },
    },
    {
      Header: 'Driver Type',
      Cell: ({ data, row }: CellProps<Load>) => {
        const { driver_type } = data[row.index];
        const sx = { color: colors.cyan[300] };
        return driver_type.id === 'TEAM_DRIVER'
          ? <TwoUsers primaryColor="#6BBDB5" filled />
          : <User primaryColor="#6BBDB5" filled />;
      },
      sx: {
        padding: '15px 15px 15px 0',
      },
    },
    {
      Header: 'Equipment',
      Cell: ({ data, row }: CellProps<Load>) => {
        const { equipment_types, trailer_status } = data[row.index];
        return (
          <div>
            <PrimaryText className="text-nowrap">
              {equipment_types.map(({ name }) => name)}
            </PrimaryText>
            <SecondaryText>{capitalized(trailer_status.name)}</SecondaryText>
          </div>
        );
      },
      sx: {
        padding: '15px 15px 15px 0',
      },
    },
    {
      Header: 'Payout',
      Cell: ({ data, row }: CellProps<Load>) => {
        const { payout, total_distance } = data[row.index];
        return (
          <div>
            <PrimaryText>{payout.symbol}{payout.value / 100}</PrimaryText>
            <div style={{ color: colors.cyan[300] }}>
              {payout.symbol}
              {(payout.value / total_distance.value).toFixed(2)}/{total_distance.symbol}
            </div>
          </div>
        );
      },
      sx: {
        padding: '15px 15px 15px 0',
      },
    },
    {
      Header: 'Check',
      Cell: ({ data, row }: CellProps<Load>) => {
        const load = data[row.index];
        return <RequestCheckPopup key={load.id} load={load} />;
      },
      sx: {
        padding: '0',
      },
    },
    {
      Header: 'History',
      Cell: ({ data, row }: CellProps<Load>) => {
        const { history } = data[row.index];
        if (!history.length) {
          return null;
        }
        return <HistoryPopup history={history} />;
      },
      sx: {
        padding: '0',
      },
    },
  ], [request.domain, isDark]);

  const listLoadsQuery = useQuery({
    queryKey: ['loads', request],
    queryFn: () => apiClient.listLoads(request),
    keepPreviousData: true,
  });

  return (
    <Table
      data={listLoadsQuery.data!.data}
      columns={columns}
      tableClass="loads-table"
      pagination={listLoadsQuery.data}
      onPageChange={page => onRequestChange({ ...request, ...page })}
      hideHeader
    />
  );
};

export default LoadsPage;

function getNumberValueOrUndefined(input: HTMLInputElement) {
  return isNaN(input.valueAsNumber) ? undefined : input.valueAsNumber;
}
