import { useQuery } from 'react-query';
import { apiClient } from '../api';

export const useCompaniesHasLegendIntegration = () => {
  const listCompaniesQuery = useQuery({
    queryKey: [`companies`, { has_legend: 1, page: 1 }],
    queryFn: () => apiClient.listCompanies({ has_legend: 1, page: 1 }),
  });

  const companiesHasLegend = listCompaniesQuery.data!.data.filter(comp =>
    comp.integrations.findIndex(i => i.type === 'legend') > -1
  );

  return companiesHasLegend;
};
