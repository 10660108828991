import { createTheme, ThemeOptions } from '@mui/material';
import { renderTimeViewClock } from '@mui/x-date-pickers';
import { merge } from 'lodash/fp';

const baseThemeOpts: ThemeOptions = {
  typography: {
    fontSize: 14,
    fontFamily: 'Eudoxus Sans',
    htmlFontSize: 13,
    body1: {
      fontSize: 14,
      lineHeight: '18px',
    },
    body2: {
      fontSize: 12,
      lineHeight: '15px',
    },
    caption: {
      fontSize: 11,
      lineHeight: '14px',
    },
  },
  palette: {
    primary: {
      main: '#426BB5',
    },
    secondary: {
      main: '#6BBDB5',
      contrastText: '#ffffff',
    },
    success: {
      main: '#66C109',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '22px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          lineHeight: '18px',
          fontWeight: 700,
        },
        sizeMedium: {
          borderRadius: '8px',
          height: '40px',
          padding: '11px 20px',
        },
        sizeSmall: {
          borderRadius: '6px',
          padding: '7px 20px',
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: '20px',
        },
      },
    },
    MuiDesktopTimePicker: {
      defaultProps: {
        viewRenderers: {
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        },
      },
    },
    MuiDesktopDateTimePicker: {
      defaultProps: {
        viewRenderers: {
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          padding: '6.5px',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '.MuiListItemText-primary': {
            fontSize: '14px',
            lineHeight: '18px',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderLeftWidth: 4,
          borderLeftStyle: 'solid',
        },
        filledWarning: {
          backgroundColor: '#FFE0B3',
          color: '#FF905F',
        },
        filledSuccess: {
          backgroundColor: '#EBF7D1',
          color: '#66C109',
        },
        filledError: {
          backgroundColor: '#FCDFE0',
          color: '#F64662',
        },
        filledInfo: {
          backgroundColor: '#C2EEFF',
          color: '#4350E3',
        },
      },
    },
  },
};

const lightThemeOpts: ThemeOptions = {
  palette: {
    mode: 'light',
    text: {
      primary: '#1E1E2C',
      secondary: '#191717',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: '#DDEAF3',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          borderTop: '1px solid #CBCED4',
        },
      },
    },
  },
};

const darkThemeOpts: ThemeOptions = {
  palette: {
    mode: 'dark',
    text: {
      primary: '#ffffff',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#1E1E2C',
          backgroundImage: 'none',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: 'rgba(89, 115, 147, 0.5)',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          borderTop: '1px solid rgba(255,255,255,.25)',
        },
      },
    },
  },
};

export const lightTheme = createTheme(merge(lightThemeOpts, baseThemeOpts));

export const darkTheme = createTheme(merge(darkThemeOpts, baseThemeOpts));
