import 'react-toastify/dist/ReactToastify.css';

import React, { FC, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import TopBarProgress from 'react-topbar-progress-indicator';

import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import { DisableSidebar } from '../../_metronic/layout/core';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { WizardPopupProvider } from '../components/wizard_popup';
import notify from '../helpers/notify';
import { useBeepAudio } from '../hooks/useBeepAudio';
import { useCompaniesHasLegendIntegration } from '../hooks/useLegendIntegrations';
import { useSocket } from '../hooks/useSocket';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';

interface IProps {
  isAdminProp: boolean;
}

const PrivateRoutes = ({ isAdminProp: isAdmin }: IProps) => {
  const notifyClient = (message: string, type: string, themeMode: 'light' | 'dark') => {
    notify(message, type, themeMode);
  };

  const AccountPage = lazy(() => import('../pages/user/AccountPage'));
  const CreateCompany = lazy(() => import('../pages/user/CreateCompany'));
  const CurrentCompany = lazy(() => import('../pages/user/CurrentCompany'));
  const ChangePasswordPage = lazy(() => import('../pages/user/ChangePasswordPage'));
  const LoadsPage = lazy(() => import('../pages/user/Loads'));
  const PlansPage = lazy(() => import('../pages/admin/plans/PlansPage'));
  const PaymentMethods = lazy(() => import('../pages/user/PaymentMethods'));
  const ChangePlanPage = lazy(() => import('../pages/admin/plans/ChangePlanPage'));
  const UsersPage = lazy(() => import('../pages/admin/users/UsersPage'));
  const UpdateUserPage = lazy(() => import('../pages/admin/users/UpdateUserPage'));
  const CreateUserPage = lazy(() => import('../pages/admin/users/CreateUserPage'));
  const BillingHistory = lazy(() => import('../pages/user/BillingHistory'));
  const BillingHistoryAdmin = lazy(() => import('../pages/admin/BillingHistoryAdmin'));
  const ActivityLog = lazy(() => import('../pages/admin/ActivityLog'));
  const AccessRulesPage = lazy(() => import('../pages/admin/AccessRules'));
  const CarriersPage = lazy(() => import('../pages/admin/Carriers'));
  const InstancesPage = lazy(() => import('../pages/admin/Instances'));
  const RequestsPage = lazy(() => import('../pages/admin/Requests'));
  const HistoryPage = lazy(() => import('../pages/admin/History'));
  const ActiveRequestsPage = lazy(() => import('../pages/user/ActiveRequestsPage'));
  const SettingsPage = lazy(() => import('../pages/user/SettingsPage'));

  const companiesHasLegend = useCompaniesHasLegendIntegration();

  const queryClient = useQueryClient();
  const { audioElement, play } = useBeepAudio();
  useSocket({
    onData: (data) => {
      switch (data.type) {
        case 'booked':
          toast(`The request ${data.request.number} has been successfully booked.`);
          queryClient.invalidateQueries(`requests/${data.request.id}`);
          try {
            play();
          } catch { }
          break;
        case 'missed':
          toast(`The request ${data.request.number} has missed a load.`);
          queryClient.invalidateQueries(`requests/${data.request.id}`);
          break;
        default:
          return;
      }
      queryClient.invalidateQueries(['companies']);
    },
  });

  return (
    <>
      <WizardPopupProvider>
        <Routes>
          <Route element={<MasterLayout />}>
            {/* Redirect to Dashboard after success login/registartion */}
            <Route
              path="/*"
              element={<Navigate to={isAdmin ? '/dashboard' : '/account'} />}
            />
            {/* Pages */}
            {isAdmin && (
              <>
                <Route
                  path="/users"
                  element={
                    <SuspensedView>
                      <UsersPage />
                    </SuspensedView>
                  }
                />
                <Route
                  path="/plans"
                  element={
                    <SuspensedView>
                      <PlansPage />
                    </SuspensedView>
                  }
                />
                <Route
                  path="/plans/:id"
                  element={
                    <SuspensedView>
                      <ChangePlanPage />
                    </SuspensedView>
                  }
                />
                <Route
                  path="/users/:id"
                  element={
                    <SuspensedView>
                      <UpdateUserPage />
                    </SuspensedView>
                  }
                />
                <Route
                  path="/users/create"
                  element={
                    <SuspensedView>
                      <CreateUserPage />
                    </SuspensedView>
                  }
                />
                <Route
                  path="/billing"
                  element={
                    <SuspensedView>
                      <BillingHistoryAdmin />
                    </SuspensedView>
                  }
                />
                <Route
                  path="/access-rules"
                  element={
                    <SuspensedView>
                      <AccessRulesPage />
                    </SuspensedView>
                  }
                />
                <Route
                  path="/carriers"
                  element={
                    <SuspensedView>
                      <CarriersPage />
                    </SuspensedView>
                  }
                />
                <Route
                  path="/instances"
                  element={
                    <SuspensedView>
                      <InstancesPage />
                    </SuspensedView>
                  }
                />
                <Route
                  path="/requests"
                  element={
                    <SuspensedView>
                      <RequestsPage />
                    </SuspensedView>
                  }
                />
              </>
            )}
            <Route path="dashboard" element={<DashboardWrapper />} />
            <Route
              path="/account"
              element={
                <SuspensedView>
                  <AccountPage />
                </SuspensedView>
              }
            />
            <Route
              path="/companies/create"
              element={
                <SuspensedView>
                  <CreateCompany onNotify={notifyClient} />
                </SuspensedView>
              }
            />
            <Route
              path="/companies/:id"
              element={
                <SuspensedView>
                  <CurrentCompany onNotify={notifyClient} />
                </SuspensedView>
              }
            />
            <Route
              path="/change-password"
              element={
                <SuspensedView>
                  <ChangePasswordPage />
                </SuspensedView>
              }
            />
            <Route
              path="/payment-methods"
              element={
                <SuspensedView>
                  <PaymentMethods />
                </SuspensedView>
              }
            />
            <Route
              path="/billing-history"
              element={
                <SuspensedView>
                  <BillingHistory />
                </SuspensedView>
              }
            />
            <Route
              path="/log"
              element={
                <SuspensedView>
                  {/*<ChangePasswordPage />*/}
                  <ActivityLog />
                </SuspensedView>
              }
            />
            <Route
              path="/loads"
              element={
                <SuspensedView>
                  <LoadsPage />
                </SuspensedView>
              }
            />
            <Route
              path="/history"
              element={
                <SuspensedView>
                  <HistoryPage />
                </SuspensedView>
              }
            />
            <Route
              path="/history"
              element={
                <SuspensedView>
                  <HistoryPage />
                </SuspensedView>
              }
            />
            <Route
              path="/active-requests"
              element={
                <SuspensedView>
                  <ActiveRequestsPage />
                </SuspensedView>
              }
            />
            {companiesHasLegend.length > 0 && (
              <>
                <Route
                  path="/past-requests"
                  element={
                    <SuspensedView>
                      <RequestsPage />
                    </SuspensedView>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <SuspensedView>
                      <SettingsPage />
                    </SuspensedView>
                  }
                />
                <Route
                  path="/loads"
                  element={
                    <SuspensedView>
                      <LoadsPage />
                    </SuspensedView>
                  }
                />
              </>
            )}
            {/* Page Not Found */}
            <Route path="*" element={<Navigate to="/error/404" />} />
          </Route>
        </Routes>
      </WizardPopupProvider>
      <ToastContainer />
      {audioElement}
    </>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  );
};

export { PrivateRoutes };
