import { ThemeProvider } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FC, Suspense, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { useMantineColorScheme } from '@mantine/core';
import { ConfirmProvider } from 'material-ui-confirm';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import { MasterInit } from '../_metronic/layout/MasterInit';
import { ThemeModeProvider, useThemeMode } from '../_metronic/partials/layout/theme-mode/ThemeModeProvider';
import { AuthInit } from './modules/auth';
import { darkTheme, lightTheme } from './theme';

const MaterialApp = () => {
  const { mode } = useThemeMode();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useMemo(() => {
    return {
      light: lightTheme,
      dark: darkTheme,
      system: prefersDarkMode ? darkTheme : lightTheme,
    }[mode];
  }, [mode]);

  const { setColorScheme } = useMantineColorScheme();
  useEffect(() => {
    setColorScheme(mode === 'system' ? 'auto' : mode);
  }, [mode]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <LayoutProvider>
          <AuthInit>
            <ConfirmProvider>
              <Outlet />
              <MasterInit />
              <ToastContainer />
            </ConfirmProvider>
          </AuthInit>
        </LayoutProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

const App: FC = ({}) => {
  return (
    <ThemeModeProvider>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <MaterialApp />
        </I18nProvider>
      </Suspense>
    </ThemeModeProvider>
  );
};

export { App };
