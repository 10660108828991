import { Box, Table as MUITable, TableBody, TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import clsx from 'clsx';
import { Column, useTable } from 'react-table';
import { NonIdealState } from '../non_ideal_state';
import { PaginationBar } from '../pagination';

export interface TableProps<Data extends object> {
  data: Data[];
  columns: Column<Data>[];
  tableClass?: string;
  hideHeader?: boolean;
  pagination?: {
    current_page: number;
    total: number;
    per_page: number;
  };
  onPageChange?: (value: { page: number; per_page: number }) => void;
}

export function Table<Data extends object>({
  columns,
  data,
  hideHeader,
  pagination,
  onPageChange,
}: TableProps<Data>) {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ data, columns });

  if (!data.length) {
    return <NonIdealState />;
  }
  return (
    <>
      <Box sx={{ overflowX: 'auto' }}>
        <MUITable
          {...getTableProps()}
          sx={{
            borderTop: '1px dashed #4D4A4B',
            borderBottom: '1px dashed #4D4A4B',
            '.MuiTableCell-root': {
              borderStyle: 'dashed',
              color: isDark ? '#ffffff' : '#151520',
            },
          }}
        >
          {!hideHeader && (
            <TableHead>
              {headerGroups.map(headerGroup => (
                <TableRow
                  {...headerGroup.getHeaderGroupProps()}
                  className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0"
                >
                  {headerGroup.headers.map((column, index) => (
                    <TableCell {...column.getHeaderProps()} className={clsx({ 'pl-0': index === 0 })}>
                      {column.render('Header')}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
          )}
          <TableBody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        sx={{
                          fontSize: '1rem',
                          ...(cell.column as any).sx,
                        }}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MUITable>
      </Box>
      {pagination && pagination.per_page > 0 && (
        <Box mt={2}>
          <PaginationBar
            {...pagination}
            onPageChange={onPageChange}
          />
        </Box>
      )}
    </>
  );
}
