import { Box, Button, Card, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography, useTheme } from '@mui/material';
import { FC, ReactElement, Suspense, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { WithChildren } from '../../../_metronic/helpers';
import { EnableSidebar, PageTitle } from '../../../_metronic/layout/core';
import { apiClient, BookedStat, ListLoadsRequest } from '../../api';
import { CurrencyFormat } from '../../components/format';
import { CalendarAnnual } from '../../components/icons/CalendarAnnual';
import { CalendarDaily } from '../../components/icons/CalendarDaily';
import { CalendarWeekly } from '../../components/icons/CalendarWeekly';
import { useAuth } from '../../modules/auth';
import { LoadsTable } from '../user/Loads';

const defaultListLoadsReq = { page: 1, per_page: 10, is_recently_booked: 1 };

const DashboardPage: FC<WithChildren> = () => {
  const [request, setRequest] = useState<ListLoadsRequest>({ ...defaultListLoadsReq, domain: '' });

  const meQuery = useQuery(['me'], () => apiClient.me());
  const domainsCount = meQuery.data?.domains?.length ?? 0;
  const hasDomains = domainsCount > 0;

  useEffect(() => {
    if (meQuery.data?.domains?.length) {
      setRequest({ domain: meQuery.data.domains[0].id, ...defaultListLoadsReq });
    }
  }, [meQuery.data]);

  const navigate = useNavigate();
  const openDownloadExtensionUrl = () => {
    window.open(process.env.REACT_APP_EXTENSION_URL, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <Grid container spacing="20px">
        <Grid item xs={12}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: { xs: '25px 30px' },
              gap: '15px',
            }}
          >
            <Grid container rowGap="12px">
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: '18px',
                    lineHeight: '23px',
                    fontWeight: 700,
                  }}
                >
                  Welcome to JungleBark
                </Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    lineHeight: '21px',
                  }}
                >
                  Intelligent Amazon Relay dispatcher is ready to work! Click "Download Extension" to start working.
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={5}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: 'flex-end',
                  alignItems: { xs: 'stretch', md: 'center' },
                  flexShrink: 0,
                  gap: { xs: '12px', md: '18px' },
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => openDownloadExtensionUrl()}
                  sx={{}}
                >
                  Download Extension
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => navigate('/companies/create')}
                >
                  Subscribe Now
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Suspense fallback={<CircularProgress />}>
          {request.domain && <Stat domain={request.domain} />}
        </Suspense>
        {hasDomains && (
          <Grid item xs={12}>
            <Card sx={{ padding: '25px 30px', display: 'flex', flexDirection: 'column', gap: '30px' }}>
              <Grid
                container
                alignItems="center"
                sx={{
                  gap: { xs: '18px', md: 0 },
                }}
              >
                <Grid xs={12} md={8} item fontSize="18px" fontWeight={700} lineHeight="23px">
                  Recently Booked Loads by Legend Customers (1 Week)
                </Grid>
                {domainsCount > 1 && (
                  <Grid xs={12} md={4} item>
                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel>Domain</InputLabel>
                      <Select
                        value={request.domain ?? ''}
                        label="Domain"
                        onChange={({ target }) => {
                          setRequest({ ...request, domain: target.value });
                        }}
                      >
                        {meQuery.data?.domains!.map(domain => (
                          <MenuItem key={domain.id} value={domain.id}>{domain.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <Box>
                <Suspense fallback={<CircularProgress />}>
                  {request.domain && (
                    <LoadsTable
                      request={request}
                      onRequestChange={setRequest}
                    />
                  )}
                </Suspense>
              </Box>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
};

interface StatProps {
  domain?: string;
}

const Stat: FC<StatProps> = ({ domain }) => {
  const getStatisticsQuery = useQuery({
    queryKey: ['statistics', domain],
    queryFn: () => apiClient.getStatistics({ domain }),
  });

  return (
    <>
      <Grid item xs={12} md={4} lg={4}>
        <BookedLoadsStat
          icon={
            <Box color="#F78C63">
              <CalendarDaily size={48} />
            </Box>
          }
          title="Daily Booked Loads"
          {...getStatisticsQuery.data?.daily_booked!}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <BookedLoadsStat
          icon={
            <Box color="#426BB5">
              <CalendarWeekly size={48} />
            </Box>
          }
          title="Monthly Booked Loads"
          {...getStatisticsQuery.data?.monthly_booked!}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <BookedLoadsStat
          icon={
            <Box color="#6BBDB5">
              <CalendarAnnual size={48} />
            </Box>
          }
          title="Annual Booked Loads"
          {...getStatisticsQuery.data?.annual_booked!}
        />
      </Grid>
    </>
  );
};

interface BookedLoadsStatProps extends BookedStat {
  icon: ReactElement;
  title: string;
}

const BookedLoadsStat: FC<BookedLoadsStatProps> = ({ icon, title, value, unit }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  return (
    <Card sx={{ padding: '20px' }}>
      <Stack flexDirection={'row'} sx={{ gap: '15px' }}>
        {icon}
        <Stack sx={{ gap: '7px' }}>
          <Typography sx={{ color: isDark ? '#ffffff' : '#1E1E2C' }}>
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              lineHeight: '25px',
              color: isDark ? '#ffffff' : '#191717',
              fontWeight: 700,
            }}
          >
            <CurrencyFormat value={value / 100} unit={unit} />
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

const DashboardWrapper: FC = () => {
  const { currentUser } = useAuth();

  return (
    <EnableSidebar>
      {currentUser && (
        <PageTitle breadcrumbs={[]}>
          Dashboard
        </PageTitle>
      )}
      <DashboardPage />
    </EnableSidebar>
  );
};

export { DashboardWrapper };
